.main-wrapper .chat-contents {
	display: flex;
	display: -webkit-flex;
}
.main-wrapper .chat-contents .sidebar-group {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	position: relative;
	z-index: 99;
	width: 340px;
}
.main-wrapper .chat-contents .sidebar-group .sidebar {
	background: #fff;
	overflow: hidden;
	width: 340px;
	display: flex;
	flex-direction: column;
	flex: 1;
	border-right: 1px solid rgba(224, 224, 224, 1);
}
.dropdown-menu .dropdown-item span {
    margin-right: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.dropdown-menu .dropdown-item {
    font-size: 14px;
    cursor: pointer;
    padding: 7px 14px;
    font-weight: 500;
    color: #424242;
}
.main-wrapper .chat-contents .sidebar-group .sidebar:not(.active) {
	display: none;
}

.header-top {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	font-weight: 600;
	background-color: #E65425;
}

.header-top .logo {
	margin-top: 0 !important;
}

.header-top > span {
	font-size: 22px;
}

.header-top ul {
	margin-bottom: 0;
	display: flex;
}

.header-top ul.header-action {
	margin-top: 14px !important;
}

.chat-header ul {
	margin-bottom: 0;
}

.chat-header ul li:not(.list-inline-item) {
	margin-bottom: 0;
	width: 48px;
	margin-right: 15px !important;
}

.chat-header ul li:last-child {
	margin-right: 0 !important;
}

.sidebar-body {
	flex: 1;
	overflow: auto;
	padding: 24px 24px 10px;
	width: 100%;
}
.pin-chat img {
	margin-right: 6px;
}
.sidebar-body .profile-name {
	font-weight: 600;
	color: #4B0973;
	font-size: 18px;
	margin-bottom: 0;
}

.online-profile {
	position: relative;
}

.online-profile span {
	position: relative;
	color: #565656;
	font-size: 13px;
	padding-left: 10px;
}

.online-profile span:before {
	content: "";
	position: absolute;
	left: 0;
	top: 7px;
	background-color: #49E073;
	width: 8px;
	height: 8px;
	border-radius: 50px;
}

.close_profile4 {
	color: #000;
	border-color: #e6e6e6;
}

.sidebar .user-list li a,
.sidebar .user-list li .list-user-blk {
	padding: 10px;
	display: flex;
	display: -webkit-flex;
	cursor: pointer;
	margin-bottom: 15px;
	border: 1px solid #E0E0E0;
	box-shadow: none;
	border-radius: 5px;
	position: relative;
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
}

.sidebar .user-list li a:hover,
.sidebar .user-list li .list-user-blk:hover {
	background-color: #F8ECFF;
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
}

.sidebar .user-list li.item-typing {
	background-color: #F1F6FF;
}

.sidebar .user-list li figure {
	margin-right: 1rem;
}

.sidebar .user-list li .users-list-body {
	flex: 1;
	position: relative;
	min-width: 0px;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.lock-icon {
	color: #000000;
}

.sidebar .user-list li .users-list-body > div:first-child {
	min-width: 0;
	display: flex;
	flex-direction: column;
	flex: 1;
}
.parti-notify-check {
	margin-bottom: 0 !important;
	display: flex;
	align-items: center;
}

.sidebar .user-list li .users-list-body h5 {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 3px;
	color: #0A0A0A;
}

.sidebar .user-list li .users-list-body p {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-bottom: 0;
	color: #232323;
	font-size: 14px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.sidebar .user-list li .users-list-body p .bx-map {
	font-size: 16px;
}
.sidebar .user-list li .users-list-body p.missed-call-col {
	color: #DE3E44;
}
.sidebar .user-list li .users-list-body .last-chat-time {
	padding-left: 15px;
	position: relative;
}

.sidebar .user-list li .users-list-body .last-chat-time .text-muted {
	color: #424242 !important;
	font-weight: 400;
	font-size: 12px;
	margin-bottom: 5px;
	display: block;
	text-align: right;
}

.sidebar .user-list li .users-list-body .last-chat-time .contact-toggle {
	color: #E65425;
}

.sidebar .user-list li .users-list-body .last-chat-time .new-message-count {
	width: 20px;
	height: 20px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	line-height: 0;
	font-size: 11px;
	background-color: #E65425;
	color: #fff;
	border-radius: 50%;
	margin-left: auto;
	margin-top: 10px;
	position: absolute;
	right: 0;
	bottom: 0;
}

.sidebar .user-list li .users-list-body .last-chat-time .chat-toggle {
	position: absolute;
	right: 0;
	bottom: 0;
	opacity: 0;
	text-align: right;
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-ms-transition: all .5s ease;
	width: 18px;
	height: 24px;
}

.sidebar .user-list li.unread h5 {
	color: #fff;
}

.sidebar .user-list li.unread p {
	color: #fff;
}

.sidebar .user-list li.unread .text-muted {
	color: rgba(255, 255, 255, 0.4) !important;
}

.sidebar .user-list li.user-list-item.item-typing .users-list-body p {
	color: #0D9B35;
}

.rightside_tab {
	padding-top: 5px !important;
}
.chat-pin {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
}
.chat-pin img {
	width: 10px;
}
.chat-pin .check {
	color: #53BDEB;
}
.chat-pin .count-message {
	background: #E65425;
	width: 20px;
	height: 20px;
	border-radius: 50px;
	font-size: 12px;
	font-weight: 400;
	color: #fff;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.select-message-box {
	margin-top: 50px;
}
.select-message-box h4 {
	margin-bottom: 5px;
}
.select-message-box p {
	margin-top: 0;
	margin-bottom: 24px;
	color: #424242;
	font-size: 16px;
	font-weight: 400;
}
.select-message-box .btn i {
	font-size: 15px;
}

/*-----------------
  8. Chat
-----------------------*/

.chat {
	flex: 1;
	display: flex;
	flex-direction: column;
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-ms-transition: all .5s ease;
	width: 46%;
	height: 100%;
}
.chat.setting-chat {
	filter: blur(8px);
    -webkit-filter: blur(8px);
}
.chat .chat-header .chat-options.chat-small ul li a {
	width: 35px;
	height: 35px;
}
.chat-options ul.list-inline .list-inline-item {
    margin-bottom: 0;
}
.last-seen {
	color:#424242;
	font-size: 14px;
}
.user_callog {
	display: flex;
	margin-top: 0px;
}
.chev-icon i {
	font-size: 18px;
}
.calllog_p {
	margin-left: 5px;
}
.chat-options.chat-option-profile .list-inline-item a {
	background: #F5F5F5;
	width: 38px;
	height: 38px;
	border-radius: 50px;
	color: #424242;
	padding: 0;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat-options.chat-option-profile .list-inline-item a:hover {
	background: #E65425;
	color: #fff;
}
.chat-options.chat-option-profile .list-inline {
	border-bottom: 1px solid #EDEDED;
	margin-bottom: 0;
	padding-bottom: 15px;
}
.last-seen-profile span {
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 12px;
	display: block;
}

.header_button {
	position: absolute;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	font-size: 14px;
	color: white;
	text-align: center;
	background: #ffa977;
	bottom: 40px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
}

.btn-warning:hover {
	color: #fff;
	background-color: #ffa977;
	border-color: #ffa977;
}

.right_sidebar_profile {
	margin-top: 0px;
}

.account_details,
.security_details {
	background-color: #edeef6;
	border-color: #d9d7d8;
	border-radius: 5px !important;
	border-bottom: 0 !important;
}

.close_icon {
	font-size: 18px;
}

.button_plus {
	margin-left: 0px;
}

.chat .chat-header {
	display: flex;
	display: -webkit-flex;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	padding: 13px 24px;
	align-items: center;
	background: #FFFFFF;
	border-bottom: 1px solid #E0E0E0;
	position: relative;
}

.chat-search {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
	opacity: 0;
	visibility: hidden;
}

.chat-search.visible-chat {
	opacity: 1;
	visibility: visible;
	top: 100%;
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
	z-index: 9;
}
.form-control-feedback {
    z-index: 2;
    display: block;
    text-align: center;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}
.form-control-feedback i {
	font-size: 16px;
	color: #0A0A0A;
}

.user-chat-search {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
	opacity: 0;
	visibility: hidden;
}

.user-chat-search.visible-chat {
	opacity: 1;
	visibility: visible;
	top: 100%;
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
	z-index: 9;
}

.chat-search input[type="text"],
.user-chat-search input[type="text"] {
	padding: 8px 8px 8px 35px;
	width: 100%;
	min-height: 47px;
	border-radius: 0px 0px 5px 5px;
	border: 1px solid #E5E7EB;
	font-size: 13px;
	background-color: #fff;
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
}

.chat-search input[type="text"]:hover,
.user-chat-search input[type="text"]:hover,
.chat-search input[type="text"]:focus,
.user-chat-search input[type="text"]:focus,
.chat-search input[type="text"]:focus-visible,
.user-chat-search input[type="text"]:focus-visible {
	border-color: #F3F3F3;
}
.all-chats {
	position: relative;
}
.close-btn-chat,
.user-close-btn-chat {
	width: 25px;
	height: 25px;
	border-radius: 25px;
	color: #0A0A0A;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	position: absolute;
	right: 12px;
	top: 9px;
	cursor: pointer;
}

.main_content {
	width: 100% !important;
}

.chat .chat-header .user-details {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
}

.avatar-main {
	height: 50px;
    width: 50px;
}

.chat .chat-header .user-details figure {
	margin-right: 1rem;
}

.chat .chat-header .user-details h5 {
	font-size: 15px;
	font-weight: 600;
	margin-bottom: 4px;
	line-height: 1;
	color: #424242;
}

.archive-btn {
	border: 1px solid #008024;
    color: #008024;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    padding: 2px 6px;
	margin-right: 1.5rem;
}

.archive-btn-grey {
	border: 1px solid #909090;
    color: #909090;
    border-radius: 4px;
    font-size: 9px;
    font-weight: 500;
    padding: 2px 6px;
	margin-top: .25rem;
}

.right_sidebar_icon {
	list-style: none;
}

.chat .chat-header .chat-options ul {
	margin-bottom: 0;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
}

.btn.btn-outline-light:hover {
	background: #e6e6e6;
}

.chat .chat-header .chat-options ul > li > a {
	font-size: 14px;
	color: #424242;
	cursor: pointer;
	width: 40px;
	height: 40px;
	padding: 0;
	border-radius: 50px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
}
.chat .chat-header .chat-options ul > li > a i {
	font-size: 20px;
}

.chat .chat-header .chat-options ul > li > a:not(.no-bg):hover {
	background-color: #F5F5F5;
	border-color: #F5F5F5;
}
.chat .chat-body {
	flex: 1;
	padding: 24px;
    padding-bottom: 100px;
}
.chat .chat-body:not(.no-message) {
	overflow: auto;
}
.chat .chat-body {
	padding-bottom: 100px;
}
.chat .chat-body.no-message {
	user-select: none;
}
.chat .chat-body .messages {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	align-items: flex-start;
	-webkit-align-items: flex-start;
}

.chat .chat-body .messages .chats {
	max-width: 75%;
	margin-bottom: 30px;
	display: flex;
	display: -webkit-flex;
}

.chat-avatar {
	padding: 8px 15px 0 8px;
	display: flex;
	display: -webkit-flex;
	align-items: flex-start;
	-webkit-align-items: flex-start;
}

.chat .chat-body .messages .chat-profile-name h6 {
	margin-bottom: 0;
	color: #0A0A0A;
	font-weight: 600;
	font-size: 15px;
}

.chat .chat-body .messages .chat-profile-name h6 span {
	font-size: 12px;
	color: #9E9E9E;
	font-weight: 400;
	padding-left: 10px;
}
.chats.chats-right .chat-profile-name {
	justify-content: end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
}
.chat-profile-name {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 5px;
}
.replay-forms .chat-profile-name h6 {
	margin-bottom: 0;
}
.replay-forms .chat-profile-name h6 span {
	color: #9E9E9E;
	font-size: 12px;
	margin-left: 10px;
}
.chat .chat-body .messages .chats:last-child {
	margin-bottom: 0;
}

.chat .chat-body .messages .chats .chat-time {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	margin-bottom: 0;
}

.chat .chat-body .messages .chats .chat-time .avatar {
	margin-right: 1rem;
}

.chat .chat-body .messages .chats .chat-time h5 {
	font-size: 15px;
	margin-bottom: 0;
}

.chat .chat-body .messages .chats .chat-time .time {
	color: #fff;
	margin-top: 2px;
	font-size: 12px;
	font-weight: 400;
}

.chat .chat-body .messages .chats .chat-time .time i {
	color: rgba(247, 247, 247, 0.5);
}

.chat .chat-body .messages .chats .chat-time .time i img {
	width: 14px;
	height: auto;
	margin-left: 2px;
	vertical-align: 0px;
}

.chat .chat-body .messages .chats .message-content {
	background-color: #fff;
	border-radius: 20px 20px 20px 0;
	padding: 14px 20px;
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	box-shadow: 0px 6px 12px 0px #00000008;
}
.chat .chat-body .messages .chats .message-content.award-link a {
	color: #424242;
	margin-bottom: 2px;
	display: block;
}
.chat .chat-body .messages .chats .message-content.chat-award-link a {
	display: flex;
	margin-bottom: 10px;
}
.chat .chat-body .messages .chats .message-content.chat-award-link .emoj-group li a {
	margin-bottom: 0px;
}
.delete-pop-btn .btn-primary {
	margin:0 0 0 10px;
}
.delete-model-head {
	padding: 40px;
}
.delete-model-head .modal-header{
	padding: 0;
	margin-bottom: 32px;
}
.delete-model-head .modal-body {
	padding: 0;
}
.delete-chat-notify {
	margin-bottom: 32px;
}
.delete-chat-notify .form-check {
	margin-bottom: 14px;
}
.chat .chat-body .messages .chats .message-content.award-link img {
	border-radius: 10px;
}

.chats.chats-right {
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
}
.group-set-wrap {
	cursor: pointer;
}

.message-star {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.message-star .avatar {
	width: 26px;
	height: 26px;
	margin-right: 10px;
}

.message-star .avatar img {
	width: 100%;
}

.message-star h6 {
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 0;
}
.message-star h6 span i {
	color: #FEC001;
}

.chat .chat-body .messages .chats .message-content a {
	color:#53BDEB;
}
.chat .chat-body .messages .chats.chats-right {
	margin-left: auto;
}
.chat-voice-group ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	padding: 0;
	margin-bottom: 0;
}
.chat-voice-group ul li span {
	width: 28px;
	height: 28px;
	background: #E65425;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat-voice-group li {
	margin-bottom: 0;
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	list-style: none;
}
.chat-voice-group li+li {
	padding-left: 10px;
}

.chat .chat-body .messages .chats.chats-right .chat-time {
	justify-content: flex-end;
}

.chat .chat-body .messages .chats.chats-right .message-content {
	background-color: #F8ECFF;
	color: #232323;
	border-radius: 15px 0px 15px 15px;
	padding: 15px;
}

.chat .chat-body .messages .chats.chats-right .chat-action-btns {
	order: 2;
	-webkit-order: 2;
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	justify-content: center;
	-webkit-justify-content: center;
}

.chat .chat-body .messages .chats.chats-right .chat-action-btns .chat-action-col {
	width: 20px;
	text-align: center;
}

.chat .chat-body .messages .chats.chats-right .chat-action-btns .chat-read-col {
	display: flex;
	display: -webkit-flex;
	align-items: flex-end;
	-webkit-align-items: flex-end;
	padding-bottom: 22px;
	color: #008024;
}

.chat .chat-body .messages .chats.chats-right .chat-content {
	order: 1;
	-webkit-order: 1;
}

.chat .chat-body .messages .chats.chats-right .chat-avatar {
	padding: 8px 0px 0 15px;
}

.chat .chat-body .messages .chats.chats-right .message-content .chat-time {
	justify-content: flex-start;
	-webkit-justify-content: flex-start;
}

.chat .chat-body .messages .chats.chats-right .message-content .chat-time .time {
	color: #909090;
}

.chat .chat-body .messages .chats.chats-right .message-content .chat-time .time i {
	color: #A3C2FF;
}

.chat .chat-body .messages .download-col {
	position: relative;
}

.chat .chat-body .messages .download-col ul {
	list-style: none;
	padding: 0;
	margin: 0 0 5px 0;
	display: flex;
	display: -webkit-flex;
}

.chat .chat-body .messages .download-col ul li+li {
	margin-left: 5px;
}

.chat .chat-body .messages .download-col .image-download-col {
	position: relative;
}

.chat .chat-body .messages .download-col .image-download-col a > img {
	border: 1px solid transparent;
	width: 80px;
	border-radius: 8px;
}
.chat .chat-body .messages .chats .message-content.fancy-msg-box .image-not-download span {
    position: absolute;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
} 
.chat .chat-body .messages .download-col .image-download-col.image-not-download a > img {
	-webkit-filter: blur(1px);
	filter: blur(1px);
}

.chat .chat-body .messages .download-col .download-action,
.chat .chat-body .messages .file-download .download-action,
.file-download-col .download-action {
	position: absolute;
	right: 8px;
	bottom: 8px;
	line-height: 0;
}

.chat .chat-body .messages .file-download .download-action {
	bottom: 14px;
}

.chat .chat-body .messages .download-col .download-action a,
.chat .chat-body .messages .file-download .download-action a,
.file-download-col .download-action a {
	color: #fff;
	font-size: 10px;
}

.chat .chat-body .messages .download-col .download-action div+div,
.chat .chat-body .messages .file-download .download-action div+div,
.file-download-col .download-action div+div {
	margin-left: 8px;
}

.chat .chat-body .messages .download-col .download-action div img,
.chat .chat-body .messages .file-download .download-action div img,
.file-download-col .download-action div img {
	width: 13px;
}

.chat .chat-body .messages .file-download {
	position: relative;
	background: #F5F5F5;
	border-radius: 10px;
	padding: 10px;
	margin-bottom: 7px;
}

.chat .chat-body .messages .file-download .file-type {
	width: 54px;
	height: 54px;
	background-color: #fff;
	border-radius: 4px;
}
.chat .chat-body .messages .file-download .file-type i {
	color: #9E9E9E;
	font-size: 24px;
}

.chat .chat-body .messages .file-download .file-details span.file-name {
	color: #0A0A0A;
	display: inline-block;
	width: 100%;
	font-size: 15px;
	font-weight: 500;
}

.chat .chat-body .messages .file-download .file-details span.file-size {
	color: #909090;
	display: inline-block;
	width: 100%;
}

.chat .chat-body .messages .file-download .file-details ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0;
	margin-bottom: 0;
}
.chat .chat-body .messages .file-download .file-details ul li {
	list-style: none;
	color: #424242;
	font-size: 14px;
	position: relative;
}
.chat .chat-body .messages .file-download .file-details ul li::before {
	content: "";
	position: absolute;
	width: 1px;
	height: 13px;
	background: #C2C2C2;
	right: -8px;
    top: 5px;
}
.chat .chat-body .messages .file-download .file-details ul li:last-child::before {
	display: none;
}
.chat .chat-body .messages .file-download .file-details ul li+li {
	padding-left: 15px;
}

.chat .chat-body .messages .file-download .file-details ul li a {
	color: #E65425;
}

.chat .chat-body .messages .file-download .download-action a {
	color: #E65425;
}

.chat-select .select2-container .select2-selection--single {
    border: 0;
    height: 24px;
}
.chat-select .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #344357;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
	font-weight: 600;
}
.chat-select .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 24px;
    right: 7px;
}
.success-pass {
	margin-bottom: 40px;
	text-align: center;
}
.chat-select .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #344357;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    height: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.chat-select .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #344357;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: -5px;
}
.chat-select .select2-container--default.form-control-sm .select2-selection--single .select2-selection__arrow {
    top: 7px;
}
.chat-select .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: #344357;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
}
.chat-select .select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 40px;
	padding-left: 0px;
}
.chat-select .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #4B4B9F;
}
.chat-select .select2-container--default .select2-selection--multiple {
	border: 1px solid #ddd;
	min-height: 40px;
}
.chat-select .select2-container--open .select2-dropdown--below {
	margin-top: 10px;
}

.review-files p {
	margin-bottom: 5px;
	color: #424242;
	font-size: 14px;
}
.like-chat-grp ul {
	padding: 0;
	margin-bottom: 0;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	margin-top: 10px;
}
.like-chat-grp ul li {
	list-style: none;
}
.like-chat-grp ul li+li {
	margin-left: 10px;
}
.like-chat-grp ul li a {
	border: 1px solid #E0E0E0;
	border-radius: 50px;
	padding: 3px 12px;
	display: block;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	color: #0A0A0A;
	font-weight: 400;
	font-size: 14px;
}
.like-chat-grp ul li.like-chat a {
	background: #FEF6DC;
}
.like-chat-grp ul li.comment-chat a {
	background: #FFE8E9;
}
.like-chat-grp ul li a img {
	margin-left: 5px;
}
.chat-line {
	margin: 0px 0 20px;
	position: relative;
	text-align: center;
	width: 100%;
}

.chat-line:before {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	background-color: #EEEEEE;
	width: 100%;
	height: 1px;
}

.chat-date {
	background-color: #fff;
	color: #0A0A0A;
	font-size: 14px;
	padding: 4px 20px;
	border-radius: 50px;
	display: inline-block;
	font-weight: 400;
	position: relative;
	border: 1px solid #E0E0E0;
}

.chat .chat-footer {
	position: relative;
}

.chat .chat-footer form {
	display: flex;
	display: -webkit-flex;
	align-items: end;
	-webkit-align-items: end;
	position: absolute;
    bottom: 0px;
    left: 0;
	right: 0;
	padding: 24px;
	background-color: #F5F5F5;
}

.chat .chat-footer form .form-buttons .btn {
	margin-left: 0px;
	color: #BDBFC7;
	font-size: 20px;
	padding: 0;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}

.chat .chat-footer form .form-buttons .btn:last-child {
	margin-right: 0;
}

.chat .chat-footer form .specker-col span {
	color: #E65425;
	font-size: 25px;
}

.chat .chat-footer form .attach-col i,
.chat .chat-footer form .smile-col i {
	color: #E65425;
	font-size: 20px;
}

.chat .chat-footer form .smile-col i {
	font-size: 22px;
}

.chat .chat-footer form .smile-col {
	right: inherit;
	left: 15px;
}

.chat .chat-footer form .attach-col {
	right: inherit;
	left: 60px;
}

.chat+.sidebar-group {
	margin-right: 0px;
}

.chat+.sidebar-group .sidebar {
	margin-right: 0px;
}
.group-comman-theme {
	padding: 0 24px;
}
.group-comman-theme h6 {
	margin-bottom: 15px;
}
.group-comman-theme ul {
	padding: 0;
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #EDEDED;
}

.group-comman-theme ul li {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	list-style: none;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 10px;
}
.group-comman-theme ul li:last-child {
	margin-bottom: 0;
}
.group-comman-theme ul li .avatar {
	flex-shrink: 0;
	margin-right: 10px;
}
.theme-content h6 {
	margin-bottom: 6px;
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
}
.theme-content p {
	margin-bottom: 0;
	text-align: start;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
	color: #424242;
	font-size: 14px;
}
.chat-message-grp {
	padding: 24px;
	padding-top: 0;
}
.chat-message-grp ul {
	padding: 0;
}
.chat-message-grp ul li {
	list-style: none;
	margin-bottom: 15px;
}
.chat-message-grp ul li:last-child {
	margin-bottom: 0px;
}
.chat-message-grp ul li a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.chat-message-grp ul li a:hover .stared-group h6 {
	color: #E65425;
}
.stared-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.stared-group span {
	width: 30px;
	height: 30px;
	border-radius: 6px;
	margin-right: 10px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.star-message {
	background: #FEF6DC;
}
.star-message i {
	color: #FEC001;
}
.mute-message {
	background: #EBFDFFFA;
}
.mute-message i {
	color: #02A2B9;
}
.block-message {
	background: #FFE8E9;
}
.block-message i {
	color: #DE3E44;
}
.report-message {
	background: #F8ECFF;
}
.report-message i {
	color: #E65425;
}
.delete-message {
	background: #FFE8E9;
}
.delete-message i {
	color: #DE3E44;
}
.count-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.count-group span {
	width: 30px;
	height: 30px;
	border-radius: 50px;
	color: #424242;
	font-size: 14px;
	background: #F5F5F5;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	margin-right: 5px;
}
.count-group i {
	color: #424242;
	font-size: 16px;
}
.stared-group h6 {
	font-size: 15px;
	font-weight: 500;
	color: #0A0A0A;
	margin-bottom: 0;
}
.chat .chat-footer form .form-buttons .btn.send-btn {
	width: 40px;
	height: 40px;
	border-radius: 6px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: #fff !important;
	background-color: #E65425;
	margin-left: 10px;
}
.set-search {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	border-radius: 50px;
	width: 28px;
	height: 28px;
}
.set-search:hover {
	background: #F5F5F5;
	border-color: #F5F5F5;
}
.chat .chat-footer form .form-buttons .btn.send-btn:hover {
	background-color: #fff;
	border-color: #E65425;
}
.chat .chat-footer form .form-buttons .btn.send-btn:hover i {
	color: #E65425;
}

.chat .chat-footer form .form-buttons .btn.send-btn:focus {
	box-shadow: none;
}
.chat .chat-footer form .smile-foot {
	margin-right: 10px;
}
.chat .chat-footer form .smile-foot .action-circle {
	width: 40px;
	height: 40px;
	background: #fff;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat .chat-footer form .smile-foot .action-circle:hover {
	background-color: #EDEDED;
	border-color: #EDEDED;
}
.chat .chat-footer form .smile-foot .action-circle i {
	color: #424242;
	font-size: 18px;
}
.chat .slimScrollDiv {
	height: 100% !important;
}
.dropdown-menu .dropdown-item span.star-msg,
.dropdown-menu .dropdown-item span.star-msg-one,
.dropdown-menu .dropdown-item span.star-msg-three,
.dropdown-menu .dropdown-item span.star-msg-four,
.dropdown-menu .dropdown-item span.star-msg-five {
	font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    color: #424242;
}

.header-action {
	list-style: none;
	margin: 0;
	padding: 0;
}

.header-action li {
	display: inline-block;
	margin-left: 10px;
}

.header-action li a {
	color: #1b1a1a;
	font-size: 18px;
}

.user-list {
	border-radius: .25rem;
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
}

.user-list li a {
	padding: 17px 20px;
	display: flex;
	display: -webkit-flex;
	cursor: pointer;
	border-radius: 6px;
	background-color: #fff;
	margin-bottom: 10px;
	border: 0;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.user-list li .avatar {
	margin-right: 0.8rem;
	display: inline-block;
	margin-bottom: 0;
	height: 46px;
	width: 46px;
	border-radius: 50%;
	position: relative;
	z-index: 0;
}

.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
	content: "";
	position: absolute;
	display: block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	top: 0px;
	right: 2px;
	border: 2px solid #fff;
	z-index: 9;
}

.avatar-online::before {
	background-color: #34D859;
}

.avatar-away::before {
	background-color: #FFE600;
}
.animate-typing-col {
	color: #0D9B35;
}
.animate-typing-col .dot {
	display: inline-block;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	margin-right: -1px;
	background: #0D9B35;
	-webkit-animation: wave 1.3s linear infinite;
	animation: wave 1.3s linear infinite;
	opacity: .6
}

.animate-typing-col .dot:nth-child(2) {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s
}

.animate-typing-col .dot:nth-child(3) {
	-webkit-animation-delay: -.9s;
	animation-delay: -.9s
}

@-webkit-keyframes wave {
	0%,
	100%,
	60% {
		-webkit-transform: initial;
		transform: initial
	}
	30% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px)
	}
}

@keyframes wave {
	0%,
	100%,
	60% {
		-webkit-transform: initial;
		transform: initial
	}
	30% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px)
	}
}
.user-list li .avatar .letter-avatar {
	width: 45px;
	height: 45px;
	border-radius: 45px;
	margin-right: 0.8rem;
	margin-bottom: 0;
	position: relative;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	background-color: #E8DBFF;
	font-size: 16px;
	font-weight: 600;
	color: #420BA1;
}

.chat_form {
	border-radius: 20px;
	margin-right: 0px;
	background: #FFFFFF;
	border: 1px solid #C2C2C2;
	box-shadow: 0px 4px 4px #F5F8FF;
	border-radius: 6px;
	height: 40px;
	padding: 10px 10px;
}

.chat .chat-footer form .form-control::-webkit-input-placeholder {
	color: #424242;
	opacity: 1;
}

.chat .chat-footer form .form-control::-moz-placeholder {
	color: #424242;
	opacity: 1;
}

.chat .chat-footer form .form-control:-ms-input-placeholder {
	color: #424242;
	opacity: 1;
}

.chat .chat-footer form .form-control:-moz-placeholder {
	color: #424242;
	opacity: 1;
}

.user-list .avatar > a > img,
.user-list .avatar > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.contact-close a {
	color: #292F4C;
	opacity: 0.5;
	border-color: #e6e6e6;
}

.main-wrapper .chat-contents .sidebar-group.right-sidebar .sidebar {
	width: 310px;
}

.primary-title {
	color: #FFA977;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0;
}

.primary-title i {
	font-size: 14px;
}

.contact-action {
	padding: 0;
	margin: 0;
	list-style: none;
}

.contact-action li {
	padding: 11px 0;
	border-bottom: 1px solid #f1f1f1;
}

.contact-action li a {
	color: #181C2F;
}

.contact-action li a {
	color: #181C2F;
	font-size: 14px;
	font-weight: bold;
}

.contact-action li.report-contact a,
.contact-action li.delete-chat a,
.contact-action li.sign-out a {
	color: #f00;
}

.left-sidebar {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-ms-transition: all .5s ease;
}

.left-sidebar-wrap {
	background: #FBFBFB;
	overflow: hidden;
	width: 320px;
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	flex: 1;
}

.right-sidebar {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	background-color: #FAFBFF;
	opacity: 100%;
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-ms-transition: all .5s ease;
	position: relative;
	width: 320px;
}

.right-sidebar.video-right-sidebar {
	width: 371px;
}
.video-call-chat .left-chat-title.with-bg {
	background: transparent;
}
.video-call-chat .left-chat-title {
    border: 0;
}
.chat.video-call-chat .chat-body {
	background-image: none;
}
.video-call-chat .chat-title h4 {
    font-size: 18px;
    font-weight: 600;
    color: #424242;
}
.video-call-chat .chat-title span {
    font-size: 12px;
    font-weight: 400;
    color: #424242;
}
.chat.video-call-chat .chat-body .messages .chats .message-content {
	background: #F5F5F5;
}
.chat.video-call-chat .chat-body .messages .chats.chats-right .message-content {
	background: #F8ECFF;
}
.right-sidebar.video-right-sidebar .chat .chat-body {
	padding-bottom: 110px;
}
.member-details {
	margin-bottom: 10px;
}
.chat-member-details {
	padding: 0 24px;
}
.chat-member-details .member-details ul {
	padding: 0;
	border-bottom: 1px solid #EDEDED;
}
.chat-member-details .member-details ul li {
	list-style: none;
	margin-bottom: 15px;
}
.chat-member-details .member-details ul li h6 {
	font-size: 15px;
	font-weight: 400;
	color: #424242;
	margin-bottom: 5px;
}
.chat-member-details .member-details ul li h5 {
	font-size: 15px;
	font-weight: 500;
	color: #0A0A0A;
	margin-bottom: 0px;
}
.chat-member-details .member-details ul li span {
	font-size: 16px;
	font-weight: 400;
	color: #0A0A0A;
}
.chat-member-details .member-details ul li a {
	font-size: 15px;
	font-weight: 500;
	color: #E65425;
	border-radius: 100px;
	padding: 5px;
    background: #F5F5F5;
	display: flex;
	align-items: center;
	justify-content: center;
}
.chat-member-details .member-details ul li a+a {
	margin-left: 10px;
}
.chat-member-details .member-details ul li a:hover {
	color: #0A0A0A;
}
.right-sidebar .right-sidebar-wrap {
	background-color: #fff;
	border-left: 1px solid #E0E0E0;
	border-radius: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
	height: calc(100vh - 0px);
}

.right-sidebar .right-sidebar-wrap .sidebar-body {
	padding: 0;
}

.right-sidebar .right-sidebar-wrap .right-sidebar-profile {
	padding: 24px;
	padding-bottom: 0;
	margin-bottom: 15px;
}

.right-sidebar.show-right-sidebar {
	margin-right: 0;
}

.right-sidebar.video-right-sidebar.show-right-sidebar {
	margin-right: 0;
}

.right-sidebar.video-right-sidebar .chat {
	margin-left: 0 !important;
	display: block;
}

.right-sidebar.hide-right-sidebar {
	margin-right: -320px;
}

.right-sidebar.video-right-sidebar.hide-right-sidebar {
	margin-right: -371px;
}

.right-sidebar .contact-close_call .close_profile,
.status-modal .custom-status-close {
	width: 25px;
	height: 25px;
	border-radius: 25px;
	color: #0A0A0A;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	background-color: #F5F5F5;
    border-color: #F5F5F5;
}
.group-description .description-sub h5 span a {
	width: 25px;
	height: 25px;
	border-radius: 25px;
	color: #0A0A0A;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
    border-color: #F5F5F5;
}

.list_group_notread {
	color: #ffa977 !important;
}
.tooltip > .tooltip-inner {
    text-align: left;
    max-width: 500px;
    --bs-tooltip-bg: #E65425;
}
.tooltip {
    --bs-tooltip-bg: #E65425;
}
.tooltip .arrow {
	display: none;
	background-color: #E65425;
}
.tooltip .tooltip-inner {
	background-color: #E65425;
}
.chat-cont-type {
   display: flex;
   align-items: center;
   justify-content: center;
}
.chat-cont-type .chat-type-wrapper {
   margin-bottom: 0;
}
.chat-cont-type .chat-type-wrapper p {
	color: #9E9E9E;
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 0;
}

.group-type-radio {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  
  .group-type-radio input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
  }
  .group-type-radio {
	font-size: 16px;
	font-weight: 400;
	color: #424242;
	margin-right: 24px;
  }
  

  .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 22px;
	width: 22px;
	background-color: #fff;
	border: 1px solid #E4E4E4;
	border-radius: 50%;
  }
  .pass-login label {
	color: #0A0A0A;
	font-size: 14px;
	font-weight: 500;
  }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #E65425 !important;
  }
  
  .group-type-radio input:checked ~ .checkmark {
	background-color: #E65425;
  }
  
  
  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  .group-type-radio input:checked ~ .checkmark:after {
	display: block;
  }

  .group-type-radio .checkmark:after {
	   top: 4px;
	  left: 4px;
	  width: 12px;
	  height: 12px;
	  border-radius: 50%;
	  background: white;
  }

.right-sidebar-profile > .avatar {
	background: rgba(66, 11, 161, 0.1);
}
.right-sidebar-profile .profile-name span i {
	font-size: 16px;
}

.group_img {
	position: relative;
	display: inline-block;
}
.group-name-search {
	margin-bottom: 14px;
}
.parti-wrapper {
	margin-bottom: 14px;
	display: flex;
	align-items: center;
}

.group_header {
	width: 38px !important;
	height: 38px !important;
	border: 2px solid #FFFFFF;
}

.last-chat-time i.missed-col {
	width: 17px;
	display: inline-block;
	margin-top: 10px;
}

.last-chat-time i.missed-col img {
	max-width: 100%;
	height: auto;
}
.right-sidebar-head {
	padding: 0px 24px;
}
.right-sidebar-head.share-media .share-media-blk {
    margin-bottom: 20px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	
}
.right-sidebar-head h5 {
    font-size: 15px;
    font-weight: 600;
    color: #1F2937;
    margin-bottom: 0px;
}
.right-sidebar-head.share-media .about-media-tabs .nav-tabs {
	margin-bottom: 15px;
	background: #EDEDED;
	padding: 5px;
	border-radius: 8px;
	display: flex;
    justify-content: space-between;
}
.right-sidebar-head.share-media .about-media-tabs {
	border-bottom: 1px solid #EDEDED;
	padding-bottom: 10px;
	margin-bottom: 15px;
}
.right-sidebar-head.share-media .about-media-tabs .nav .nav-item.active {
    background: #fff;
    border-radius: 6px;
    color: #424242;
}
.right-sidebar-head.share-media .about-media-tabs .nav .nav-item {
	padding: 2px 10px;
    font-size: 14px;
    color: #424242;
    font-weight: 500;
    border: 0;
	margin-right: 10px;
}
.right-sidebar-head.share-media .about-media-tabs .nav .nav-item:last-child {
	margin-right: 0px;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li {
    margin: 0px 6px 6px 0px;
	position: relative;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li img {
    border-radius: 8px;
	width: 84px;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li span i {
	font-size: 20px;
	color: #fff;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li:hover span i {
	color: #eee;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li.blur-media img {
    filter: blur(2px);
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li span {
    position: absolute;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.media-file {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 15px;
}
.media-file:last-child {
	margin-bottom: 0;
}
.media-doc-blk {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.media-doc-blk span {
	width: 50px;
	height: 50px;
	border-radius: 5px;
	background: #F5F5F5;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	margin-right: 10px;
}
.media-doc-blk span i {
	font-size: 24px;
}
.document-detail ul  {
	padding: 0;
	margin-bottom: 0;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.document-detail ul li {
	list-style: none;
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	position: relative;
}
.document-detail ul li::before {
	content: "";
	position: absolute;
	width: 1px;
	height: 13px;
	background: #C2C2C2;
	right: -8px;
    top: 5px;
}
.document-detail ul li:last-child::before {
	display: none;
}
.document-detail ul li+li {
	padding-left: 15px;
}
.document-detail h6 {
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
}
.media-download a {
	color: #424242;
}
.social-media-col {
	margin-top: 35px;
}
.media-link-grp {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 15px;
}
.media-link-detail h6 a {
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 5px;
}
.media-link-detail span a {
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	word-break: break-all;
}
.media-link-detail a:hover {
	color: #E65425;
}
.share-media-blk a {
	color: #E65425;
	font-weight: 500;
}
.link-img img {
	border-radius: 4px;
	margin-right: 10px;
}

.social-media-col:after {
	content: "";
	display: table;
	clear: both;
}

.social-media-col ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.social-media-col ul li {
	float: left;
}

.social-media-col ul li+li {
	margin-left: 10px;
}

.social-media-col ul li a {
	color: #420BA1;
	font-size: 18px;
}

.social-media-col h6,
.settings-col h6 {
	font-weight: 600;
	color: #000;
	font-size: 15px;
	margin-bottom: 20px;
}

.settings-col {
	margin-top: 35px;
}

.settings-col:after {
	content: "";
	display: table;
	clear: both;
}

.settings-col ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.settings-col ul li {
	color: #939393;
	font-weight: 500;
}

.settings-col ul li+li {
	margin-top: 15px;
}

.settings-col .switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 23px;
	margin-bottom: 0;
	margin-right: 10px;
}

.settings-col .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.settings-col .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.settings-col .slider:before {
	position: absolute;
	content: "";
	height: 15px;
	width: 15px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

.settings-col input:checked+.slider {
	background-color: #2196F3;
}

.settings-col input:focus+.slider {
	box-shadow: 0 0 1px #2196F3;
}

.settings-col input:checked+.slider:before {
	-webkit-transform: translateX(16px);
	-ms-transform: translateX(16px);
	transform: translateX(16px);
}

.settings-col .slider.round {
	border-radius: 34px;
}

.settings-col .slider.round:before {
	border-radius: 50%;
}

.report-col {
	margin-top: 35px;
	padding: 12px;
	padding-bottom: 50px;
}

.report-col:after {
	content: "";
	display: table;
	clear: both;
}

.report-col ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.report-col ul:after {
	content: "";
	display: table;
	clear: both;
}

.report-col ul li {
	display: block;
	width: 100%;
	font-weight: 500;
	position: relative;
}

.report-col ul li a {
	color: #FF0000;
	padding-left: 25px;
}

.report-col ul li a span {
	position: absolute;
	left: 0;
	top: 0px;
	font-size: 16px;
}

.report-col ul li+li {
	margin-top: 19px;
}

.file-download-col {}

.file-download-col:after {
	content: "";
	display: table;
	clear: both;
}

.file-download-col ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.file-download-col ul li {
	float: left;
	width: 50%;
	padding: 5px;
}

.file-download-col ul li.full-width {
	width: 100%;
}

.file-download-col ul li .image-download-col {
	position: relative;
}

.file-download-col ul li .image-download-col a > img {
	width: 100%;
}

.load-more-btn {
	font-weight: 600;
	margin-top: 10px;
}

.load-more-btn i {
	background: #0057FF;
	border-radius: 50%;
	height: 12px;
	width: 12px;
	color: #fff;
	line-height: 8px;
	font-size: 12px;
	vertical-align: middle;
}
.chat-message-grp.chat-encrypt {
	padding-bottom: 0;
}
.chat-message-grp.chat-encrypt ul {
	border-bottom: 1px solid #EDEDED;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.participants-list {
	padding: 0px 24px;
}
.participants-list-group {
	border-bottom: 1px solid #EDEDED;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.participants-list-group h6 {
	margin-bottom: 15px;
	font-size: 15px;
	font-weight: 500;
	color: #0A0A0A;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.participants-list .participants-list-group h6.parti-search {
	font-size: 20px !important;
	display: flex;
	align-items: center;
}
.participants-list ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.participants-list ul li {
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	background-color: #fff;
	padding: 10px;
}

.participants-list ul li+li {
	margin-top: 10px;
}

.participants-list .users-list-body h5 {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 5px;
	color: #0A0A0A;
}

.participants-list .users-list-body p {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-bottom: 0;
	color: #424242;
	font-size: 14px;
	font-weight: 400;
}

.participants-list .users-list-body {
	flex: 1;
	position: relative;
	min-width: 0px;
	display: flex;
	display: -webkit-flex;
	width: 100%;
}
.user-list li a.admin-shade {
	box-shadow: none;
}
.participants-list .admin-profiles {
	display: inline-block;
	color: #E65425;
	border-radius: 6px;
	font-size: 12px;
	padding: 2px 10px;
	font-weight: 500;
	background: #F8ECFF;
}
.participants-list .admin-profiles:hover {
	background: #E65425;
	color: #fff;
}
.chat-message-grp.group-exits {
	padding: 0px 24px;
	padding-bottom: 30px;
}
.grp-delete .chat-action-col {
	display: flex;
	align-items: center;
	height: 100%;
}

.grp-delete .chat-action-col > a {
	color: #C8C8D8;
}

.chat-action-col > a {
	color: #424242;
}

.chat-profile-name .chat-action-col  a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.darkmode-btn {
	width: 35px;
	height: 32px;
	background-color: #0057FF;
	border: 1px solid #FBFBFB;
	border-radius: 5px;
	color: #fff;
	font-size: 16px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	margin: 0 auto;
}

.darkmode-btn:hover,
.darkmode-btn:focus,
.add-contacts-btn:hover,
.add-contacts-btn:focus {
	color: #fff;
}

.add-contacts-btn {
	width: 25px;
	height: 25px;
	border-radius: 30px;
	background-color: #E65425;
	color: #fff;
	font-size: 10px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	margin: 0 auto;
}

.group-call-tab .nav-tabs .nav-link {
	color: #E65425;
	border: 0;
	border-radius: 10px;
	font-weight: 500;
	padding: 6px 25px;
}

.group-call-tab .nav-tabs .nav-link:hover {
	color: #E65425;
}

.group-call-tab .nav-tabs .nav-link+.nav-link {
	margin-left: 10px;
}

.group-call-tab .nav-tabs .nav-link.active {
	background-color: rgba(90, 7, 139, 0.1);
	color: #E65425;
}

.group-call-tab nav {
	padding: 0 15px 20px;
	position: relative;
}

.group-call-tab nav:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #E2E2E2;
	height: 1px;
}

/*-----------------
  9. Settings
-----------------------*/

.profile-cover-avatar {
	display: -ms-flexbox;
	display: flex;
	margin: auto;
	border-radius: 50%;
	position: relative;
	width: 144px;
	height: 144px;
}

.profile-cover-avatar .avatar-img {
	display: block;
	max-width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	pointer-events: none;
	border-radius: 50%;
}

.profile-cover-avatar input[type="file"] {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: -1;
	opacity: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(19, 33, 68, .25);
	border-radius: 50%;
	transition: .2s;
}
.profile-cover {
	margin-bottom: 30px;
}
.avatar-edit {
	position: absolute;
	bottom: 0;
	right: 0;
	cursor: pointer;
	border-radius: 50%;
	width: 42px;
	height: 42px;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
	background-color: #E65425;
	border-radius: 50%;
	transition: .2s;
	border: 3px solid #FFFFFF;
}
.profile-cover-avatar .avatar-edit i {
	font-size: 20px;
}

.avatar-edit svg {
	width: 15px;
	height: 15px;
}

.profile-email {
	color: #9B9B9B;
	font-size: 13px;
}

.profile-country {
	color: #E65425;
	font-size: 1rem;
}

.profile-name {
	color: #E65425;
	font-size: 20px;
	font-weight: 600;
}

.info-title {
	color: #424242;
	font-weight: 400;
	font-size: 14px;
}

.info-text {
	font-weight: 600;
	color: #0A0A0A;
	font-size: 16px;
}
.profile-listout {
	margin-bottom: 20px;
}
.social-icons {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.social-icons a:hover i {
	color: #E65425;
}
.social-icons a i {
	font-size: 20px;
	color: #0A0A0A;
}
.social-nav li {
	list-style: none;
	display: inline-block;
	padding-right: 15px;
}

.social-nav li:last-child {
	padding-right: 0;
}

.social-nav li a i {
	font-size: 16px;
	color: #420BA1;
}

.social-nav li a i:hover {
	color: #E65425;
}

.settings-card {
	padding: 24px;
	background: #fff;
}

.settings-control ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.settings-control ul li {
	margin-bottom: 15px;
}
.settings-control ul li:last-child {
	margin-bottom: 0px;
}
.settings-control ul li a {
	font-weight: 500;
	color: #424242;
	font-size: 15px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.settings-control ul li a:hover {
	color: #E65425;
}
.settings-control ul li a i {
	font-size: 16px;
}
.setting-card-list {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.setting-card-list img,
.setting-card-list i {
	margin-right: 5px;
}

.settings-control .switch {
	position: relative;
	display: inline-block;
	width: 35px;
	height: 17px;
	margin-bottom: 0;
}

.settings-control .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.settings-control .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.settings-control .slider:before {
	position: absolute;
	content: "";
	height: 10px;
	width: 10px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

.settings-control input:checked+.slider {
	background-color: #2196F3;
}

.settings-control input:focus+.slider {
	box-shadow: 0 0 1px #2196F3;
}

.settings-control input:checked+.slider:before {
	-webkit-transform: translateX(16px);
	-ms-transform: translateX(16px);
	transform: translateX(16px);
}

.settings-control .slider.round {
	border-radius: 34px;
}

.settings-control .slider.round:before {
	border-radius: 50%;
}
.right-sidebar.full-width {
	width: 900px;
	background: #fff;
	position: relative;
}

.page-header {
	padding: 12px 15px;
	align-items: center;
	box-shadow: 0px 4px 4px #f5f8ff;
	border-radius: 5px;
	background: #FFFFFF;
	border: 1px solid #F4EEFF;
}

.page-header p {
	font-size: 13px;
}

.page-header h5 {
	font-weight: 700;
	color: #420BA1;
	margin-bottom: .25rem;
}

.full-width .right-sidebar-wrap {
	border: 0;
}

.settings-tab .nav-tabs .nav-link.active {
	color: #0057FF;
	font-weight: 500;
	border-color: #0057FF;
}

.settings-tab .nav-tabs .nav-link {
	border: 0;
	color: #000;
	border-bottom: 2px solid transparent;
	padding: 3px 9px;
	font-size: 15px;
	font-weight: 500;
	margin-right: 30px;
}

.settings-tab .nav.nav-tabs {
	border-bottom: 1px solid #F0F0F0;
	margin-bottom: 1.5rem;
}

.form-body .form-group label {
	font-weight: 600;
	color: #464646;
}

.social-settings h4 {
	color: #E65425;
	font-size: 18px;
	font-weight: 600;
}

.form-body input::-webkit-input-placeholder {
	color: #000000;
}

.form-body input:-ms-input-placeholder {
	color: #000000;
}

.form-body input::placeholder {
	color: #000000;
}

.settings-delete h5 {
	color: #E65425;
	font-size: 18px;
	font-weight: 600;
}

.settings-delete p {
	color: #949494;
	font-size: 12px;
}

.settings-header {
	background: #F9F9F9;
	padding: 15px;
}

.settings-header h5 {
	color: #E65425;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 7px;
}

.settings-header p {
	color: #949494;
	font-size: 12px;
}

.settings-control.full-options {
	padding: 0 0 25px;
}

.settings-control.full-options ul li {
	color: #515151;
	font-weight: 500;
	border-bottom: 0;
	padding: 25px 20px 0;
}

.device-details h5 {
	font-weight: 700;
	color: #420BA1;
	font-size: 16px;
	margin-bottom: .25rem;
}

.device-details p {
	font-weight: 500;
	color: #9B9B9B;
}

.security-settings {
	border: 1px solid #F9F9F9;
}

.success-icon.delete-tab {
	padding: 40px 0;
}

.success-icon.delete-tab p {
	font-size: 20px;
}

.setting-title-head h4 {
	font-size: 18px;
    font-weight: 600;
    color: #0A0A0A;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}
.setting-title-head h4 a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 5px;
}
.account-setting  {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 24px;
}
.account-setting a:hover {
	color: #650681;
}
.account-setting a i {
	font-size: 18px;
}
.account-setting  h5 {
	color: #424242;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 0;
}
.setting-profile-card {
	padding: 24px;
	border-bottom: 1px solid #C2C2C2;
}
.last-seen .active-online {
	color: #1BA345;
}
.social-profile-blk {
	margin-bottom: 20px;
}
.social-profile-blk ul li {
	margin-bottom: 15px;
}
.social-profile-blk ul li:last-child {
	margin-bottom: 0px;
}
.social-profile-blk ul li a {
	border: 1px solid #C2C2C2;
	display: block;
	border-radius: 6px;
	padding: 10px;
}
.social-profile-blk ul li h6 {
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 0;
}
.login-security h6 {
	color: #0A0A0A;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 15px;
}
.form-control {
    min-height: 40px;
    font-size: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
}
.form-control::placeholder {
	color:#212529;
}
.login-security .pass-group {
	position: relative;
}
.login-security form {
	margin-bottom: 20px;
}
.login-security .pass-group .toggle-passwords,
.login-security .pass-group .toggle-password,
.login-security .pass-group .conform-toggle-password {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    font-weight: 500;
}
.pass-login  {
	margin-bottom: 15px;
	position: relative;
	z-index: 1;
}
.pass-login h4 {
	margin-bottom: 15px;
	color: #0A0A0A;
	font-size: 14px;
	font-weight: 500;
}
.settings-switch ul li {
	background: #F8ECFF;
	padding: 10px;
	margin-bottom: 24px;
	font-size: 16px;
	color: #0A0A0A;
	font-weight: 500;
}
.settings-switch .switch {
	position: relative;
	display: inline-block;
	width: 35px;
	height: 17px;
	margin-bottom: 0;
}

.settings-switch .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.settings-switch .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #EDEDED;
	border: 1px solid #C2C2C2;
	-webkit-transition: .4s;
	transition: .4s;
}

.settings-switch .slider:before {
	position: absolute;
	content: "";
	height: 13px;
	width: 13px;
	left: 1px;
	bottom: 1px;
	background-color: #C2C2C2;
	-webkit-transition: .4s;
	transition: .4s;
}

.settings-switch input:checked+.slider {
	background-color: #1BA345;
	border: 1px solid #1BA345;
}
.settings-switch input:checked+.slider::before {
	background-color: #fff;
}

.settings-switch input:focus+.slider {
	box-shadow: 0 0 1px #1BA345;
}

.settings-switch input:checked+.slider:before {
	-webkit-transform: translateX(16px);
	-ms-transform: translateX(16px);
	transform: translateX(16px);
	background-color: #fff;
}

.settings-switch .slider.round {
	border-radius: 34px;
}

.settings-switch .slider.round:before {
	border-radius: 50%;
}
.privacy-detail-blk {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.privacy-detail-blk {
	margin-bottom: 20px;
}
.privacy-content h6 {
	color: #0A0A0A;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 5px;
}
.privacy-content p {
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0;
}
.privacy-content.chat-content {
	width: 100%;
}
.privacy-content.chat-content h6 {
	margin-bottom: 0;
}
.privacy-content.chat-content h6 a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.logout-group p {
	color: #424242;
	font-size: 16px;
}
.connectetapps {
    margin-bottom: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.connectetappsimg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.connectet-img {
    width: 46px;
    height: 46px;
    background: #eee;
    padding: 10px;
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.connectet-content {
    margin-left: 15px;
}
.connectet-content p {
    color: #424242;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 5px;
}
.connectet-content h6 {
	color: #0A0A0A;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
}
.connectetappscontent {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.connectetappscontent .btn-primary {
	color: #E65425!important;
	border: 2px solid #E65425;
	background: #fff !important;
	margin-right: 10px;
}
.connectetappscontent .btn-primary:hover {
	box-shadow: 0 50px 0 0 #E65425 inset;
	color: #fff !important;
}
.device-modal.modal .modal-dialog {
	max-width: 900px;
}
.device-remove a {
	width: 38px;
	height: 38px;
	background: #F5F5F5;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	color: #424242;
}
.device-remove a:hover {
	background: #ff0100;
	color: #fff;
}
.manage-device-detail {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 30px;
}
.manage-device-detail a:hover img {
	filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg) brightness(42%) contrast(108%)
}
.manage-histry h6 {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
}
.manage-histry p {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0px;
}
.modal-body label span {
    color: #DE3E44;
}
.user-block-group .search_chat {
	position: relative;
	margin-bottom: 14px;
}
.user-block-group .search_chat .form-control {
	padding-left: 35px;
}
.user-block-group {
	margin-bottom: 15px;
}
.user-block-group h5 {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 14px;
	color: #0A0A0A;
}
.recent-block-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 15px;
}
.user-block-profile {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.user-block-profile .avatar {
	margin-right: 10px;
}
.user-blocked .btn-primary {
	padding: 8px 20px;
	font-size: 14px;
}
.block-user-name h6 {
	font-size: 15px;
	color: #0A0A0A;
	font-weight: 500;
	margin-bottom: 5px;
}
.block-user-name span {
	color: #424242;
	font-weight: 400;
	font-size: 14px;
}
.user-blocked i {
	font-size: 20px;
	color: #424242;
}
.privacy-content.notify-content h6 {
	margin-bottom: 0;
}
.border-group {
	border-bottom: 1px solid #EDEDED;
	padding-bottom: 20px;
}
.notification-group .notify-check .form-check .custom-check .checkmark {
    top: -10px;
	left: 10px;
}
.message-info-box {
	margin-bottom: 15px;
}
.message-info-box h6 {
	font-size: 16px;
	font-weight: 500;
	color: #0A0A0A;
	margin-bottom: 5px;
}
.message-info-box span {
	font-size: 14px;
	font-weight: 400;
	color: #424242;
}
.message-info-box .check-active {
	color: #53BDEB;
	margin-right: 10px;
	font-size: 20px;
}
.message-info-box .check {
	color: #424242;
	margin-right: 10px;
	font-size: 20px;
}
.message-info-box .sent {
	color: #9E9E9E;
	margin-right: 10px;
	font-size: 20px;
}
.right-sidebar.hide-message-right {
    margin-right: -320px;
}
.select2-results__option {
	font-size: 14px;
}
.select2-container .select2-selection--single {
    border: 1px solid #E0E0E0;
    height: 40px;
	min-width: 118px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #424242;
    font-size: 14px;
    font-weight: normal;
    line-height: 40px;
	font-weight: 400;
	width: 155px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
    right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #424242;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    height: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #424242;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: -5px;
}
.select2-container--default.form-control-sm .select2-selection--single .select2-selection__arrow {
    top: 7px;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: #424242;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 10px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #4B4B9F;
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #ddd;
	min-height: 40px;
}
.select2-container--open .select2-dropdown--below {
	margin-top: 0px;
}
.select2-container {
	z-index: 1111;
}
.languages-group .select2-container--default .select2-selection--single .select2-selection__rendered {
	width: auto;
}
/*-----------------
  10. Contact
-----------------------*/

.chat.chat-contact {
	background-image: none;
	background: #F8F6FA;
}
.chat.chat-contact .slimscroll {
	padding: 24px;
}
.chat.chat-contact .chat-header {
	background: none;
	border: 0;
	padding: 0;
}
.chat-options.chat-contact-list ul > li > a {
	background: #fff;
}
.chat.chat-contact .chat-header .avatar {
	width: 100px;
	height: 100px;
}
.chat.chat-contact .chat-header .user-details h5 {
	font-size: 18px;
	color: #0A0A0A;
	font-weight: 600;
}
.call-video span {
	font-size: 20px;
	color: #424242;
}
.call-video span + span{
	margin-left: 10px;
}

.fav-title.contact-title h6 {
	font-size: 18px;
	color: #0A0A0A;
}
.personal-info h5 {
	color: #0A0A0A;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 24px;
}
.personal-info ul li {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #424242;
	margin-bottom: 20px;
}
.personal-info ul li:last-child {
	margin-bottom: 0;
}
.personal-info ul li h6 {
	font-size: 14px;
	font-weight: 400;
	width: 50%;
	margin-bottom: 0;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.personal-info ul li h6 i {
	font-size: 20px;
	margin-right: 10px;
}
.personal-info ul li h6 img {
	margin-right: 10px;
}
.personal-info ul li span {
	font-size: 16px;
	font-weight: 500;
}
.personal-info ul li span a {
	color: #53BDEB;
}
.contact-added {
	font-size: 20px;
}
.dropdown-menu.contact-menus span i {
	font-size: 16px;
	color: #424242;
}
.cal-icon {
	position: relative;
}
.cal-icon span {
	position: absolute;
	right: 10px;
	top: 50%;
	-webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.user-block-group .notify-check .form-check .custom-check .checkmark {
    top: -10px;
	left: 10px;
}
.mute-chat-btn .btn-primary:hover img {
	filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg) brightness(42%) contrast(108%)
}
.chat .chat-footer form .form-buttons .btn.send-btn:hover {
	background-color: #fff;
	border-color: #E65425;
}
.chat .chat-footer form .form-buttons .btn.send-btn:hover i {
	color: #E65425;
}

.chat .chat-footer form .form-buttons .btn.send-btn:focus {
	box-shadow: none;
}
.chat .chat-footer form .smile-foot {
	margin-right: 10px;
}
.chat .chat-footer form .smile-foot .action-circle {
	width: 40px;
	height: 40px;
	background: #fff;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat .chat-footer form .smile-foot .action-circle:hover {
	background-color: #EDEDED;
	border-color: #EDEDED;
}
.chat .chat-footer form .smile-foot .action-circle i {
	color: #424242;
	font-size: 18px;
}
.chat .slimScrollDiv ,.right_sidebar_profile .slimScrollDiv {
	height: 100% !important;
}
.dropdown-menu .dropdown-item span.star-msg,
.dropdown-menu .dropdown-item span.star-msg-one,
.dropdown-menu .dropdown-item span.star-msg-three,
.dropdown-menu .dropdown-item span.star-msg-four,
.dropdown-menu .dropdown-item span.star-msg-five {
	font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    color: #424242;
}

.header-action {
	list-style: none;
	margin: 0;
	padding: 0;
}

.header-action li {
	display: inline-block;
	margin-left: 10px;
}

.header-action li a {
	color: #1b1a1a;
	font-size: 18px;
}

.user-list {
	border-radius: .25rem;
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
}

.user-list li a {
	padding: 17px 20px;
	display: flex;
	display: -webkit-flex;
	cursor: pointer;
	border-radius: 6px;
	background-color: #fff;
	margin-bottom: 10px;
	border: 0;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.user-list li .avatar {
	margin-right: 0.8rem;
	display: inline-block;
	margin-bottom: 0;
	height: 46px;
	width: 46px;
	border-radius: 50%;
	position: relative;
	z-index: 0;
}

.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
	content: "";
	position: absolute;
	display: block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	top: 0px;
	right: 2px;
	border: 2px solid #fff;
	z-index: 9;
}

.avatar-online::before {
	background-color: #34D859;
}

.avatar-away::before {
	background-color: #FFE600;
}
.animate-typing-col {
	color: #0D9B35;
}
.animate-typing-col .dot {
	display: inline-block;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	margin-right: -1px;
	background: #0D9B35;
	-webkit-animation: wave 1.3s linear infinite;
	animation: wave 1.3s linear infinite;
	opacity: .6
}

.animate-typing-col .dot:nth-child(2) {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s
}

.animate-typing-col .dot:nth-child(3) {
	-webkit-animation-delay: -.9s;
	animation-delay: -.9s
}

@-webkit-keyframes wave {
	0%,
	100%,
	60% {
		-webkit-transform: initial;
		transform: initial
	}
	30% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px)
	}
}

@keyframes wave {
	0%,
	100%,
	60% {
		-webkit-transform: initial;
		transform: initial
	}
	30% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px)
	}
}
.user-list li .avatar .letter-avatar {
	width: 45px;
	height: 45px;
	border-radius: 45px;
	margin-right: 0.8rem;
	margin-bottom: 0;
	position: relative;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	background-color: #E8DBFF;
	font-size: 16px;
	font-weight: 600;
	color: #420BA1;
}

.chat_form {
	border-radius: 20px;
	margin-right: 0px;
	background: #FFFFFF;
	border: 1px solid #C2C2C2;
	box-shadow: 0px 4px 4px #F5F8FF;
	border-radius: 6px;
	height: 40px;
	padding: 10px 10px;
}

.chat .chat-footer form .form-control::-webkit-input-placeholder {
	color: #424242;
	opacity: 1;
}

.chat .chat-footer form .form-control::-moz-placeholder {
	color: #424242;
	opacity: 1;
}

.chat .chat-footer form .form-control:-ms-input-placeholder {
	color: #424242;
	opacity: 1;
}

.chat .chat-footer form .form-control:-moz-placeholder {
	color: #424242;
	opacity: 1;
}

.user-list .avatar > a > img,
.user-list .avatar > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.contact-close a {
	color: #292F4C;
	opacity: 0.5;
	border-color: #e6e6e6;
}

.main-wrapper .chat-contents .sidebar-group.right-sidebar .sidebar {
	width: 310px;
}

.primary-title {
	color: #FFA977;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0;
}

.primary-title i {
	font-size: 14px;
}

.contact-action {
	padding: 0;
	margin: 0;
	list-style: none;
}

.contact-action li {
	padding: 11px 0;
	border-bottom: 1px solid #f1f1f1;
}

.contact-action li a {
	color: #181C2F;
}

.contact-action li a {
	color: #181C2F;
	font-size: 14px;
	font-weight: bold;
}
.chat .chat-footer form .form-buttons .btn.send-btn:hover {
	background-color: #fff;
	border-color: #E65425;
}
.chat .chat-footer form .form-buttons .btn.send-btn:hover i {
	color: #E65425;
}

.chat .chat-footer form .form-buttons .btn.send-btn:focus {
	box-shadow: none;
}
.chat .chat-footer form .smile-foot {
	margin-right: 10px;
}
.chat .chat-footer form .smile-foot .action-circle {
	width: 40px;
	height: 40px;
	background: #FFF6F1;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat .chat-footer form .smile-foot .action-circle:hover {
	background-color:#FFF6F1;
	border-color:#FFF6F1;
}
.chat .chat-footer form .smile-foot .action-circle i {
	color: #424242;
	font-size: 18px;
}
.down-emoji-circle {
	position: absolute;
	left: 10px !important;
	top: -38px !important;
}
.emoj-action .down-emoji-circle ul,
.emoj-action-foot .down-emoji-circle ul {
	padding: 10px;
}
.down-emoji-circle ul li + li {
	margin-left: 14px	;
}
.emoj-action .down-emoji-circle ul li:last-child a,
.emoj-action-foot .down-emoji-circle ul li:last-child a  {
	width: 34px;
    height: 34px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.emoj-group-list,
.emoj-group-list-foot {
	display: none;
	position: absolute;
	top: -50px;
	left: -90px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.emoj-group-list ul,
.emoj-group-list-foot ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #fff;
	border-radius: 50px;
	padding: 5px;
}
.emoj-group-list ul li a,
.emoj-group-list-foot ul li a {
	padding: 0;
}
.emoj-group-list ul li.add-emoj a,
.emoj-group-list-foot ul li.add-emoj a {
	background: #EDEDED;
}
.emoj-group-list ul li.add-emoj a i,
.emoj-group-list-foot ul li.add-emoj a i {
	font-size: 20px;
	color: #424242;
}
.chat-hover {
	position: absolute;
    bottom: 30px;
    right: 10px;
	display: none;
}
.add-section ul li {
    float: left;
    border-radius: 50px;
    width: 28px;
    height: 28px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}
.chat .chat-body {
	flex: 1;
	padding: 24px;
}
.chat .chat-body:not(.no-message) {
	overflow: auto;
}
.chat .chat-body.no-message {
	user-select: none;
}
.chat .chat-body .messages {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	align-items: flex-start;
	-webkit-align-items: flex-start;
}

.chat .chat-body .messages .chats {
	max-width: 75%;
	margin-bottom: 30px;
	display: flex;
	display: -webkit-flex;
}

.chat-avatar {
	padding: 8px 15px 0 8px;
	display: flex;
	display: -webkit-flex;
	align-items: flex-start;
	-webkit-align-items: flex-start;
}

.chat .chat-body .messages .chat-profile-name h6 {
	margin-bottom: 0;
	color: #0A0A0A;
	font-weight: 600;
	font-size: 15px;
}

.chat .chat-body .messages .chat-profile-name h6 span {
	font-size: 12px;
	color: #9E9E9E;
	font-weight: 400;
	padding-left: 10px;
}
.chats.chats-right .chat-profile-name {
	justify-content: end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
}
.chat-profile-name {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 5px;
}
.replay-forms .chat-profile-name h6 {
	margin-bottom: 0;
}
.replay-forms .chat-profile-name h6 span {
	color: #9E9E9E;
	font-size: 12px;
	margin-left: 10px;
}
.chat .chat-body .messages .chats:last-child {
	margin-bottom: 0;
}

.chat .chat-body .messages .chats .chat-time {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	margin-bottom: 0;
}

.chat .chat-body .messages .chats .chat-time .avatar {
	margin-right: 1rem;
}

.chat .chat-body .messages .chats .chat-time h5 {
	font-size: 15px;
	margin-bottom: 0;
}

.chat .chat-body .messages .chats .chat-time .time {
	color: #fff;
	margin-top: 2px;
	font-size: 12px;
	font-weight: 400;
}

.chat .chat-body .messages .chats .chat-time .time i {
	color: rgba(247, 247, 247, 0.5);
}

.chat .chat-body .messages .chats .chat-time .time i img {
	width: 14px;
	height: auto;
	margin-left: 2px;
	vertical-align: 0px;
}

.chat .chat-body .messages .chats .message-content {
	background-color: #fff;
	border-radius: 20px 20px 20px 0;
	padding: 14px 20px;
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	box-shadow: 0px 6px 12px 0px #00000008;
}
.chat .chat-body .messages .chats .message-content.award-link a {
	color: #424242;
	margin-bottom: 2px;
	display: block;
}
.chat .chat-body .messages .chats .message-content.chat-award-link a {
	display: flex;
	margin-bottom: 10px;
}
.chat .chat-body .messages .chats .message-content.chat-award-link .emoj-group li a {
	margin-bottom: 0px;
}
.delete-pop-btn .btn-primary {
	margin:0 0 0 10px;
}
.delete-model-head {
	padding: 40px;
}
.delete-model-head .modal-header{
	padding: 0;
	margin-bottom: 32px;
}
.delete-model-head .modal-body {
	padding: 0;
}
.delete-chat-notify {
	margin-bottom: 32px;
}
.delete-chat-notify .form-check {
	margin-bottom: 14px;
}
.chat .chat-body .messages .chats .message-content.award-link img {
	border-radius: 10px;
}

.chats.chats-right {
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
}
.group-set-wrap {
	cursor: pointer;
}

.message-star {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.message-star .avatar {
	width: 26px;
	height: 26px;
	margin-right: 10px;
}

.message-star .avatar img {
	width: 100%;
}

.message-star h6 {
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 0;
}
.message-star h6 span i {
	color: #FEC001;
}

.chat .chat-body .messages .chats .message-content a {
	color:#53BDEB;
}
.chat .chat-body .messages .chats.chats-right {
	margin-left: auto;
}
.chat-voice-group ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	padding: 0;
	margin-bottom: 0;
}
.chat-voice-group ul li span {
	width: 28px;
	height: 28px;
	background: #E65425;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat-voice-group li {
	margin-bottom: 0;
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	list-style: none;
}
.chat-voice-group li+li {
	padding-left: 10px;
}

.chat .chat-body .messages .chats.chats-right .chat-time {
	justify-content: flex-end;
}

.chat .chat-body .messages .chats.chats-right .message-content {
	background-color: #F8ECFF;
	color: #232323;
	border-radius: 15px 0px 15px 15px;
	padding: 15px;
}

.chat .chat-body .messages .chats.chats-right .chat-action-btns {
	order: 2;
	-webkit-order: 2;
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	justify-content: center;
	-webkit-justify-content: center;
}

.chat .chat-body .messages .chats.chats-right .chat-action-btns .chat-action-col {
	width: 20px;
	text-align: center;
}

.chat .chat-body .messages .chats.chats-right .chat-action-btns .chat-read-col {
	display: flex;
	display: -webkit-flex;
	align-items: flex-end;
	-webkit-align-items: flex-end;
	padding-bottom: 22px;
	color: #008024;
}

.chat .chat-body .messages .chats.chats-right .chat-content {
	order: 1;
	-webkit-order: 1;
}

.chat .chat-body .messages .chats.chats-right .chat-avatar {
	padding: 8px 0px 0 15px;
}

.chat .chat-body .messages .chats.chats-right .message-content .chat-time {
	justify-content: flex-start;
	-webkit-justify-content: flex-start;
}

.chat .chat-body .messages .chats.chats-right .message-content .chat-time .time {
	color: #909090;
}

.chat .chat-body .messages .chats.chats-right .message-content .chat-time .time i {
	color: #A3C2FF;
}

.chat .chat-body .messages .download-col {
	position: relative;
}

.chat .chat-body .messages .download-col ul {
	list-style: none;
	padding: 0;
	margin: 0 0 5px 0;
	display: flex;
	display: -webkit-flex;
}

.chat .chat-body .messages .download-col ul li+li {
	margin-left: 5px;
}

.chat .chat-body .messages .download-col .image-download-col {
	position: relative;
}

.chat .chat-body .messages .download-col .image-download-col a > img {
	border: 1px solid transparent;
	width: 80px;
	border-radius: 8px;
}
.chat .chat-body .messages .chats .message-content.fancy-msg-box .image-not-download span {
    position: absolute;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
} 
.chat .chat-body .messages .download-col .image-download-col.image-not-download a > img {
	-webkit-filter: blur(1px);
	filter: blur(1px);
}

.chat .chat-body .messages .download-col .download-action,
.chat .chat-body .messages .file-download .download-action,
.file-download-col .download-action {
	position: absolute;
	right: 8px;
	bottom: 8px;
	line-height: 0;
}

.chat .chat-body .messages .file-download .download-action {
	bottom: 14px;
}

.chat .chat-body .messages .download-col .download-action a,
.chat .chat-body .messages .file-download .download-action a,
.file-download-col .download-action a {
	color: #fff;
	font-size: 10px;
}

.chat .chat-body .messages .download-col .download-action div+div,
.chat .chat-body .messages .file-download .download-action div+div,
.file-download-col .download-action div+div {
	margin-left: 8px;
}

.chat .chat-body .messages .download-col .download-action div img,
.chat .chat-body .messages .file-download .download-action div img,
.file-download-col .download-action div img {
	width: 13px;
}

.chat .chat-body .messages .file-download {
	position: relative;
	background: #F5F5F5;
	border-radius: 10px;
	padding: 10px;
	margin-bottom: 7px;
}

.chat .chat-body .messages .file-download .file-type {
	width: 54px;
	height: 54px;
	background-color: #fff;
	border-radius: 4px;
}
.chat .chat-body .messages .file-download .file-type i {
	color: #9E9E9E;
	font-size: 24px;
}

.chat .chat-body .messages .file-download .file-details span.file-name {
	color: #0A0A0A;
	display: inline-block;
	width: 100%;
	font-size: 15px;
	font-weight: 500;
}

.chat .chat-body .messages .file-download .file-details span.file-size {
	color: #909090;
	display: inline-block;
	width: 100%;
}

.chat .chat-body .messages .file-download .file-details ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0;
	margin-bottom: 0;
}
.chat .chat-body .messages .file-download .file-details ul li {
	list-style: none;
	color: #424242;
	font-size: 14px;
	position: relative;
}
.chat .chat-body .messages .file-download .file-details ul li::before {
	content: "";
	position: absolute;
	width: 1px;
	height: 13px;
	background: #C2C2C2;
	right: -8px;
    top: 5px;
}
.chat .chat-body .messages .file-download .file-details ul li:last-child::before {
	display: none;
}
.chat .chat-body .messages .file-download .file-details ul li+li {
	padding-left: 15px;
}

.chat .chat-body .messages .file-download .file-details ul li a {
	color: #E65425;
}

.chat .chat-body .messages .file-download .download-action a {
	color: #E65425;
}

.chat-select .select2-container .select2-selection--single {
    border: 0;
    height: 24px;
}
.chat-select .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #344357;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
	font-weight: 600;
}
.chat-select .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 24px;
    right: 7px;
}
.success-pass {
	margin-bottom: 40px;
	text-align: center;
}
.chat-select .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #344357;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    height: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.chat-select .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #344357;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: -5px;
}
.chat-select .select2-container--default.form-control-sm .select2-selection--single .select2-selection__arrow {
    top: 7px;
}
.chat-select .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: #344357;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
}
.chat-select .select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 40px;
	padding-left: 0px;
}
.chat-select .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #4B4B9F;
}
.chat-select .select2-container--default .select2-selection--multiple {
	border: 1px solid #ddd;
	min-height: 40px;
}
.chat-select .select2-container--open .select2-dropdown--below {
	margin-top: 10px;
}

.review-files p {
	margin-bottom: 5px;
	color: #424242;
	font-size: 14px;
}
.like-chat-grp ul {
	padding: 0;
	margin-bottom: 0;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	margin-top: 10px;
}
.like-chat-grp ul li {
	list-style: none;
}
.like-chat-grp ul li+li {
	margin-left: 10px;
}
.like-chat-grp ul li a {
	border: 1px solid #E0E0E0;
	border-radius: 50px;
	padding: 3px 12px;
	display: block;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	color: #0A0A0A;
	font-weight: 400;
	font-size: 14px;
}
.like-chat-grp ul li.like-chat a {
	background: #FEF6DC;
}
.like-chat-grp ul li.comment-chat a {
	background: #FFE8E9;
}
.like-chat-grp ul li a img {
	margin-left: 5px;
}
.chat-line {
	margin: 0px 0 20px;
	position: relative;
	text-align: center;
	width: 100%;
}

.chat-line:before {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	background-color: #EEEEEE;
	width: 100%;
	height: 1px;
}

.chat-date {
	background-color: #fff;
	color: #0A0A0A;
	font-size: 14px;
	padding: 4px 20px;
	border-radius: 50px;
	display: inline-block;
	font-weight: 400;
	position: relative;
	border: 1px solid #E0E0E0;
}

.chat .chat-footer {
	position: relative;
}

.chat .chat-footer form {
	display: flex;
	display: -webkit-flex;
	align-items: end;
	-webkit-align-items: end;
	position: absolute;
    bottom: 0px;
    left: 0;
	right: 0;
	padding: 24px;
	background-color: #fff;
}

.chat .chat-footer form .form-buttons .btn {
	margin-left: 0px;
	color: #BDBFC7;
	font-size: 20px;
	padding: 0;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}

.chat .chat-footer form .form-buttons .btn:last-child {
	margin-right: 0;
}

.chat .chat-footer form .specker-col span {
	color: #E65425;
	font-size: 25px;
}

.chat .chat-footer form .attach-col i,
.chat .chat-footer form .smile-col i {
	color: #E65425;
	font-size: 20px;
}

.chat .chat-footer form .smile-col i {
	font-size: 22px;
}

.chat .chat-footer form .smile-col {
	right: inherit;
	left: 15px;
}

.chat .chat-footer form .attach-col {
	right: inherit;
	left: 60px;
}

.chat+.sidebar-group {
	margin-right: 0px;
}

.chat+.sidebar-group .sidebar {
	margin-right: 0px;
}
.group-comman-theme {
	padding: 0 24px;
}
.group-comman-theme h6 {
	margin-bottom: 15px;
}
.group-comman-theme ul {
	padding: 0;
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #EDEDED;
}

.group-comman-theme ul li {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	list-style: none;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 10px;
}
.group-comman-theme ul li:last-child {
	margin-bottom: 0;
}
.group-comman-theme ul li .avatar {
	flex-shrink: 0;
	margin-right: 10px;
}
.theme-content h6 {
	margin-bottom: 6px;
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
}
.theme-content p {
	margin-bottom: 0;
	text-align: start;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
	color: #424242;
	font-size: 14px;
}
.chat-message-grp {
	padding: 24px;
	padding-top: 0;
}
.chat-message-grp ul {
	padding: 0;
}
.chat-message-grp ul li {
	list-style: none;
	margin-bottom: 15px;
}
.chat-message-grp ul li:last-child {
	margin-bottom: 0px;
}
.chat-message-grp ul li a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.chat-message-grp ul li a:hover .stared-group h6 {
	color: #E65425;
}
.stared-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.stared-group span {
	width: 30px;
	height: 30px;
	border-radius: 6px;
	margin-right: 10px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.star-message {
	background: #FEF6DC;
}
.star-message i {
	color: #FEC001;
}
.mute-message {
	background: #EBFDFFFA;
}
.chat-page .slimscroll {
    height: calc(100vh - 130px) !important;
}
.chat-page .chat .slimscroll {
    height: calc(100vh - 190px) !important;
}
.replay-forms {
	width: 100%;
    border: 1px solid #E0E0E0;
    background: #fff;
    border-radius: 10px;
}
.replay-forms .form-control {
	border: 0;
	background: transparent;
    box-shadow: none;
}
.uplaod-image {
	height: 400px;
}
.uplaod-image img {
	width: 100%;
	height: 100%;
}
.uplaod-image .chat {
	background: transparent;
	height: auto;
}
.sroll-side-view {
	height: 400px;
	overflow-y: scroll;
    overflow-x: hidden;
}
.sroll-side-view::-webkit-scrollbar {
	width: 5px;
	height: 10px;
	border-radius: 50px !important;
}
.sroll-side-view::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 50px !important;
}
.sroll-side-view::-webkit-scrollbar-thumb {
	background: #E65425;
	border-radius: 50px !important;
}
.sroll-side-view::-webkit-scrollbar-thumb:hover {
	background: #E65425;
}
.multi-load {
	border: 2px solid #c8ccc8;
    box-shadow: inset 0 0 7px grey;
    border-left-color: #5a078b;
    border-top-color: #b8beb8;
    border-right-color: #b8beb8;
    border-bottom-color: #b8beb8;
    text-align: center;
    box-sizing: border-box;
}
.right-sidebar .right-sidebar-wrap {
	background-color: #fff;
	border-left: 1px solid #E0E0E0;
	border-radius: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
	height: calc(100vh - 0px);
}

.right-sidebar .right-sidebar-wrap .sidebar-body {
	padding: 0;
}
.right-sidebar .right-sidebar-wrap .right-sidebar-profile {
	padding: 24px;
	padding-bottom: 0;
	margin-bottom: 15px;
}
.right-sidebar.video-right-sidebar.show-right-sidebar {
	margin-right: 0;
}
.right-sidebar.video-right-sidebar .chat {
	margin-left: 0 !important;
	display: block;
}
.right-sidebar.hide-right-sidebar {
	margin-right: -320px;
}
.right-sidebar.video-right-sidebar.hide-right-sidebar {
	margin-right: -371px;
}

.right-sidebar .contact-close_call .close_profile,
.status-modal .custom-status-close {
	width: 25px;
	height: 25px;
	border-radius: 25px;
	color: #0A0A0A;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	background-color: #F5F5F5;
    border-color: #F5F5F5;
}
.group-description .description-sub h5 span a {
	width: 25px;
	height: 25px;
	border-radius: 25px;
	color: #0A0A0A;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
    border-color: #F5F5F5;
}

.list_group_notread {
	color: #ffa977 !important;
}
.tooltip > .tooltip-inner {
    text-align: left;
    max-width: 500px;
    --bs-tooltip-bg: #E65425;
}
.tooltip {
    --bs-tooltip-bg: #E65425;
}
.tooltip .arrow {
	display: none;
	background-color: #E65425;
}
.tooltip .tooltip-inner {
	background-color: #E65425;
}
.chat-cont-type {
   display: flex;
   align-items: center;
   justify-content: center;
}
.chat-cont-type .chat-type-wrapper {
   margin-bottom: 0;
}
.chat-cont-type .chat-type-wrapper p {
	color: #9E9E9E;
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 0;
}

.group-type-radio {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  
  .group-type-radio input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
  }
  .group-type-radio {
	font-size: 16px;
	font-weight: 400;
	color: #424242;
	margin-right: 24px;
  }
  

  .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 22px;
	width: 22px;
	background-color: #fff;
	border: 1px solid #E4E4E4;
	border-radius: 50%;
  }
  .pass-login label {
	color: #0A0A0A;
	font-size: 14px;
	font-weight: 500;
  }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #E65425 !important;
  }
  
  .group-type-radio input:checked ~ .checkmark {
	background-color: #E65425;
  }
  
  
  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  .group-type-radio input:checked ~ .checkmark:after {
	display: block;
  }

  .group-type-radio .checkmark:after {
	   top: 4px;
	  left: 4px;
	  width: 12px;
	  height: 12px;
	  border-radius: 50%;
	  background: white;
  }

.right-sidebar-profile > .avatar {
	background: transparent;
}
.right-sidebar-profile .profile-name span i {
	font-size: 16px;
}

.group_img {
	position: relative;
	display: inline-block;
}
.group-name-search {
	margin-bottom: 14px;
}
.parti-wrapper {
	margin-bottom: 14px;
	display: flex;
	align-items: center;
}

.group_header {
	width: 38px !important;
	height: 38px !important;
	border: 2px solid #FFFFFF;
}

.last-chat-time i.missed-col {
	width: 17px;
	display: inline-block;
	margin-top: 10px;
}

.last-chat-time i.missed-col img {
	max-width: 100%;
	height: auto;
}
.right-sidebar-head {
	padding: 0px 24px;
}
.right-sidebar-head.share-media .share-media-blk {
    margin-bottom: 20px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	
}
.right-sidebar-head h5 {
    font-size: 15px;
    font-weight: 600;
    color: #1F2937;
    margin-bottom: 0px;
}
.right-sidebar-head.share-media .about-media-tabs .nav-tabs {
	margin-bottom: 15px;
	background: #EDEDED;
	padding: 5px;
	border-radius: 8px;
	display: flex;
    justify-content: space-between;
}
.right-sidebar-head.share-media .about-media-tabs {
	border-bottom: 1px solid #EDEDED;
	padding-bottom: 10px;
	margin-bottom: 15px;
}
.right-sidebar-head.share-media .about-media-tabs .nav .nav-item.active {
    background: #fff;
    border-radius: 6px;
    color: #424242;
}
.right-sidebar-head.share-media .about-media-tabs .nav .nav-item {
	padding: 2px 10px;
    font-size: 14px;
    color: #424242;
    font-weight: 500;
    border: 0;
	margin-right: 10px;
}
.right-sidebar-head.share-media .about-media-tabs .nav .nav-item:last-child {
	margin-right: 0px;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li {
    margin: 0px 6px 6px 0px;
	position: relative;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li img {
    border-radius: 8px;
	width: 84px;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li span i {
	font-size: 20px;
	color: #fff;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li:hover span i {
	color: #eee;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li.blur-media img {
    filter: blur(2px);
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li span {
    position: absolute;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.media-file {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 15px;
}
.media-file:last-child {
	margin-bottom: 0;
}
.media-doc-blk {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.media-doc-blk span {
	width: 50px;
	height: 50px;
	border-radius: 5px;
	background: #F5F5F5;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	margin-right: 10px;
}
.media-doc-blk span i {
	font-size: 24px;
}
.document-detail ul  {
	padding: 0;
	margin-bottom: 0;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.document-detail ul li {
	list-style: none;
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	position: relative;
}
.document-detail ul li::before {
	content: "";
	position: absolute;
	width: 1px;
	height: 13px;
	background: #C2C2C2;
	right: -8px;
    top: 5px;
}
.document-detail ul li:last-child::before {
	display: none;
}
.document-detail ul li+li {
	padding-left: 15px;
}
.document-detail h6 {
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
}
.media-download a {
	color: #424242;
}
.social-media-col {
	margin-top: 35px;
}
.media-link-grp {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 15px;
}
.media-link-detail h6 a {
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 5px;
}
.media-link-detail span a {
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	word-break: break-all;
}
.media-link-detail a:hover {
	color: #E65425;
}
.share-media-blk a {
	color: #E65425;
	font-weight: 500;
}
.link-img img {
	border-radius: 4px;
	margin-right: 10px;
}

.social-media-col:after {
	content: "";
	display: table;
	clear: both;
}
.chat-page .chat-contents {
    padding: 0;
    overflow: hidden;
    border: 1px solid #E0E0E0;
    margin-top: 50px;
    height: calc(100vh - 120px);
    border-radius: 15px;
    display: flex;
}
.chat-page-wrapper {
    padding-top: 90px;
}
.header-two .dropdown-item img{
	margin-right: 5px;
}
.emoj-group {
	position: absolute;
	top: 50%;
	right: -100px;
	opacity: 0;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
	-webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.rig-emoji-group {
	position: absolute;
	right: 210px;
}
.wrap-emoji-group {
	position: absolute;
	right: 290px;
	z-index: 9;
}
.status-message-box h6 {
	font-size: 20px;
	color: #0A0A0A;
	margin-bottom: 24px;
}
.status-message-box .avatar {
	width: 92px;
	height: 92px;
	margin-bottom: 24px;
}
.status-message-box .view-link a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.status-message-box .view-link a:hover {
	color: #650681;
}
.status-message-box .view-link p {
	font-size: 16px;
	font-weight: 400;
	color: #424242;
}
.chat-hover {
	position: absolute;
    bottom: 30px;
    right: 10px;
	display: none;
}
.chat-user-list {
	position: relative;
}
.chat-user-list:hover .chat-hover {
	display: block;
}
.chat-user-list:hover .chat-pin,
.chat-hover:hover .chat-pin  {
	margin-right: 15px;
}
.chat-hover span {
	cursor: pointer;
}

/*-----------------
  15. Call
-----------------------*/

.chat.call-log-group {
	background-image: none;
	background-color: #F8F6FA;
}
.chat.call-log-group .chat-header {
	border: 0;
	background: transparent;
}
.chat.call-log-group .chat-body {
	background-image: none;
}
.missed-call-chat {
	color: #DE3E44;
}
.outgoing-call {
	color: #02A2B9;
}
.incoming-call {
	color: #1BA345;
}
.phone-income i {
	color: #1BA345;
	margin-right: 3px;
}
.phone-missed i {
	color: #DE3E44;
	margin-right: 3px;
}
.phone-outgoing i {
	color: #02A2B9;
	margin-right: 3px;
}
.dark-mode-toggle {
	display: none;
}
.dark-mode-toggle.active {
	display: block;
}
/*-----------------
  16. Modal
-----------------------*/

.modal-header {
	padding: 24px;
	color: #484848;
	border-radius: 10px 10px 0 0;
	border-bottom: 0;
}
.setting-wall {
	padding: 40px;
}
.set-wall-header {
	padding: 0;
	margin-bottom: 32px;
}
.wall-img {
	margin-bottom: 10px;
	position: relative;
}
.active.wall-img img {
	border: 3px solid #650681;
}
.active .theme-image-content {
    display: block;
}
.theme-image-content {
    display: none;
    position: absolute;
    top: 40px;
    right: 55px;
}
.theme-image-content i {
    font-size: 18px;
    z-index: 99;
    position: relative;
    color: #fff;
    border-radius: 50px;
}
.wall-set-body {
	margin-top: 22px;
}
.wall-set-body .wall-btn {
	justify-content: end;
}
.wallper-card-wrapper {
	padding: 0px 10px 10px 10px;
}
.privacy-detail-blk span a {
    font-size: 16px;
}
.wall-set-body .wall-btn .btn-primary {
	margin: 0 0 0 10px;
}
.wall-img img {
	border-radius: 10px;
	cursor: pointer;
}
.setting-wall .wall-set-body {
	padding: 0;
}
.list-inline-item .dropdown-menu {
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    box-sizing: border-box;
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-width: 173px;
    z-index: 999;
}
.list-inline-item .dropdown-menu .dropdown-item{
    display: flex;
    align-items: center;
}

@media screen and (max-width: 991.98px){
    .main-wrapper .chat-contents .sidebar-group {
        width: 100%;
    }
    .left-sidebar.hide-left-sidebar {
        margin-left: -991px;
    }
    .left-sidebar.hide-left-sidebar {
        margin-left: -991px;
    }
    .chat.show-chatbar {
        display: block;
        margin-left: 0;
    }
    .right-sidebar.hide-right-sidebar {
        margin-right: -991px;
    }
   
}