/*
Author       : Dreamguys
Template Name: Freevance - Bootstrap Admin Template
Version      : 1.0
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Helper Class
4. Bootstrap Classes
5. Modal
6. Select2
7. Nav Tabs
8. Components
9. Header
10. Chat
11. Search
12. Sidebar
13. Calendar
14. Inbox
15. Mail View
16. Error
17. Content
18. Login
19. Notifications
20. Dashboard
21. Invoices
22. Settings
23. Switch Buttons
24. Profile
25. Subscription
26. Report
27. Users
28. Responsive

========================================*/

/*-----------------
	1. General
-----------------------*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,900;1,700&display=swap');

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/MaterialIcons-Regular.eot);
	/* For IE6-8 */
	src: local('Material Icons'), local('MaterialIcons-Regular'), url(../fonts/MaterialIcons-Regular.woff2) format('woff2'), url(../fonts/MaterialIcons-Regular.woff) format('woff'), url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}
html {
	height: 100%;
}
body {
	background-color: #fff;
	color: #000;
	font-family: 'Titillium Web', sans-serif;
	font-size: 16px;
	height: 100%;
	line-height: 1.5;
	overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Titillium Web', sans-serif;
	margin-top: 0;
	color: #2c3038;
}
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
.form-control {
	border: 1px solid #D7D7D7;
	border-radius: 5px;
	box-shadow: none;
	color: #333;
	padding: .54688rem .875rem;
	font-size: 14px;
	height: 46px;
	line-height: 1.6;
	font-weight: 400;
	font-family: 'Titillium Web', sans-serif;
}
.form-control:focus {
	box-shadow: 0 0 10px rgba(136, 136, 136, 0.125); 
	outline: 0 none;
	border-color: #c1c9d0;;
}
.form-control-sm {
    height: calc(1.5em + 1rem);
    padding: .4375rem .65625rem;
    font-size: .8125rem;
    line-height: 1.5;
    border-radius: .25rem;
}
.form-control-lg {
    height: calc(1.5em + 1.875rem);
    padding: .875rem 1.09375rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .3125rem;
}
a {
	color: #FF5B37;
	text-decoration: none;
}
a:hover {
    color: #FF5B37;
    text-decoration: none;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
p:last-child { 
    margin-bottom: 0;
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 0;
}
input,
button,
a {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}
input,
input:focus,
button,
button:focus {
	outline: none;
}
input[type="file"] {
	height: auto;
	min-height: calc(1.5em + .75rem + 2px);
}
input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
textarea.form-control {
	resize: vertical;
}
.navbar-nav > li {
	float: left;
}
.form-group {
	margin-bottom: 1.25rem;
} 
label {
    display: inline-block;
    margin-bottom: 10px;
    font-family: 'Titillium Web', sans-serif;
}
.form-group img {
	width:100%;
}
.input-group .form-control {
	height: 40px;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-color: rgba(0, 0, 0, 0.1);
}
.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
}
ul li{
	list-style: none;
}
p:last-child{
	margin-bottom: 0;
}
.f-12 {
	font-size: 12px;
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #FF5B37;
    background-color: #FF5B37;

}

/*-----------------
	2. Table
-----------------------*/

.btn-approve {
	background: #00B47E;
	color: #fff;
	width: 115px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background: #FF5B37;
}
.nav-pills .nav-link {
   color: #FF5B37;
   border: 1px solid #FFE6E1;
   border-radius: 5px;
}
.reviews-menu-links {
	margin-top: 20px;
	margin-bottom: 20px;
}
.projects-card .nav-pills .nav-link.active, .projects-card .nav-pills .show>.nav-link {
	color: #fff;
    background-color: #FF5B37;
    font-weight: 500;
}
.projects-card .nav-pills .nav-link {
    border-radius: 5px;
    color: #333;
    border: 0;
    font-size: 16px;
}
.report-btns {
	text-align: right;
}
.table-btn {
	width:205px;
	font-size: 12px;
	background: #ff5b37;
	color: #fff;
}
.table-btn:hover {
	background: #DD4B39;
    color: #fff;
}
.btn-blue {
	background: #131135;
	color: #fff;
}
.btn-enable {
	background: #131135;
	color: #fff;
	width:122px;
}
.btn-disable {
	background: #FF0000;
	color: #fff;
	width: 122px;
}
.btn-enable:hover,
.btn-disable:hover {
	color: #fff;
	opacity: 0.8;
}
.table {
	color: #333;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
}
.table>:not(:first-child) {
    border-top: transparent;
}
.table>:not(caption)>*>* {
	box-shadow: unset;
	padding: 0.75rem;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
    font-size: 16px;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
	background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
	border-top: 0;
	padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
}
.table.dataTable {
	border-collapse: collapse !important;
	border: 1px solid rgba(0, 0, 0, 0.05);
}
table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}
table.table td h2.table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
	white-space: nowrap;
}
table.table td h2 a {
	color: #333;
}
table.table td h2 a:hover {
	color: #FF5B37;
}
table.table td h2 span {
	color: #888;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}
.table thead tr th {
	font-weight: 600;
	padding: 1rem;
}
.table tbody tr {
	border-bottom: 1px solid #dee2e6;
}
.table.table-center td,
.table.table-center th {
	vertical-align: middle;
}
.table-hover tbody tr:hover {
	background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
	color: #474648;
}
.table-striped thead tr {
	border-color: transparent;
}
.table-striped tbody tr {
	border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
	border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
}
.card-table .card-body .table tr td:first-child,
.card-table .card-body .table tr th:first-child {
    padding-left: 1.5rem;
}
.card-table .card-body .table tr td:last-child,
.card-table .card-body .table tr th:last-child {
    padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 1rem 0.75rem;
    white-space: nowrap;
}
.table .thead-light th {
    color: #495057;
    background-color: #f8f9fa;
    border-color: #eff2f7;
}
table.table td h2.table-avatar img {
    width: 40px;
    height: 40px;
}
div.dataTables_wrapper div.dataTables_length select {
	-webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
}
.table tbody tr:last-child {
	border-color: transparent;
}
.table td, .table th {
	border-left: transparent;
	border-right: transparent;
	white-space: nowrap;
}
.table .white-space {
	white-space: initial;
}

/*-----------------
	3. Helper Class
-----------------------*/

.block {
	display: block !important;
}
.bullets li {
	list-style: inherit;
}
#toggle_btn i {
	font-size: 22px;
}
.bg-1 {
	background: #FFB8A8;
}
.bg-1 i {
	color: #FF5B37;

}
.bg-2 {
	background-color: #d1f3ff;
}
.bg-2 i {
	color: #42cdff;
}
.bg-3 {
	background-color: #ceffd5;
}
.bg-3 i {
	color: #52fb6a;
}
.bg-4 {
	background-color: #fccaff;
}
.bg-4 i {
	color: #f555ff;
}
.bg-5 {
	background-color: #ffb800;
}
.bg-6 {
	background-color: #42cdff;
}
.bg-7 {
	background-color: #52fb6a;
}
.bg-8 {
	background-color: #f555ff;
}
.table .btn {
    white-space: nowrap;
}
table .badge {
    border-radius: 3px;
    display: inline-block;
    font-size: 13px;
    min-width: 75px;
    padding: 4px 12px;
    text-align: center;
}
.feather {
    width: 18px;
    height: 18px;
}

/*-----------------
	4. Bootstrap Classes
-----------------------*/

.btn.focus,
.btn:focus {
	box-shadow: unset;
}
.btn-block {
	width: 100%;
}
.btn-white {
    background-color: #fff;
    border-color: #e7eaf3;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.bg-primary,
.badge-primary {
	background-color: #FF5B37 !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
	background-color: #FF5b33 !important;
}
.bg-success,
.badge-success {
	background-color: #22cc62 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
	background-color: #1eae55 !important;
}
.bg-info,
.badge-info {
	background-color: #009efb !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
	background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
	background-color: #ffbc34 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
	background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
	background-color: #ef3737 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
	background-color: #e63333 !important;
}
.bg-white {
	background-color: #fff;
}
.bg-purple,
.badge-purple {
	background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #FF5B37 !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #22cc62 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #ef3737 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #009efb !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
	color: #757575 !important;
}
.btn-primary {
	background-color: #FF5B37;
	border: 1px solid #FF5B37;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color: #FF5b33;
	border: 1px solid #FF5b33;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #FF5b33;
	border: 1px solid #FF5b33;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #FF5b33;
	border-color: #FF5b33;
	color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-primary.disabled,
.btn-primary:disabled {
	background-color: #FF5B37;
	border-color: #FF5B37;
	color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success {
	background-color: #22cc62;
	border: 1px solid #22cc62
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
	background-color: #1eae55;
	border: 1px solid #1eae55;
	color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
	background-color: #1eae55;
	border: 1px solid #1eae55
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
	background-color: #1eae55;
	border-color: #1eae55;
	color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success.disabled,
.btn-success:disabled {
	background-color: #22cc62;
	border-color: #22cc62;
	color: #fff;
}
.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
	background-color: #028ee1;
	border-color: #028ee1;
	color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-info.disabled,
.btn-info:disabled {
	background-color: #009efb;
	border-color: #009efb;
	color: #fff;
}
.btn-warning {
	background-color: #ffbc34;
	border: 1px solid #ffbc34
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #e9ab2e;
	border-color: #e9ab2e;
	color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-warning.disabled,
.btn-warning:disabled {
	background-color: #ffbc34;
	border-color: #ffbc34;
	color: #fff;
}
.badge-secondary {
	background-color: #FF5b33 !important;
}
.btn-danger {
	background-color: #ef3737;
	border: 1px solid #ef3737;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
	background-color: #ec1313;
	border: 1px solid #ec1313;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
	background-color: #ec1313;
	border: 1px solid #ec1313;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #ec1313;
	border-color: #ec1313;
	color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-danger.disabled,
.btn-danger:disabled {
	background-color: #f62d51;
	border-color: #f62d51;
	color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.show > .btn-light.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled),
.show > .btn-dark.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-outline-primary {
	color: #FF5B37;
	border-color: #FF5B37;
}
.btn-outline-primary:hover {
	background-color: #FF5B37;
	border-color: #FF5B37;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #FF5B37;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: #FF5B37;
	border-color: #FF5B37;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-success {
	color: #22cc62;
	border-color: #22cc62;
}
.btn-outline-success:hover {
	background-color: #22cc62;
	border-color: #22cc62;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
	color: #22cc62;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	background-color: #22cc62;
	border-color: #22cc62;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: #00bcd4;
    border-color: #00bcd4;
}
.btn-outline-info:hover {
	color: #fff;
	background-color: #00bcd4;
	border-color: #00bcd4;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
	background-color: transparent;
	color: #00bcd4;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	background-color: #00bcd4;
	border-color: #00bcd4;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:hover {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
	background-color: transparent;
	color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: #ef3737;
	border-color: #ef3737;
}
.btn-outline-danger:hover {
	color: #fff;
	background-color: #ef3737;
	border-color: #ef3737;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
	background-color: transparent;
	color: #ef3737;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	background-color: #ef3737;
	border-color: #ef3737;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
	color: #ababab;
	border-color: #e6e6e6;
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
	color: #ababab;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #FF5B37;
	border-color: #FF5B37;
}
.pagination > li > a,
.pagination > li > span {
	color: #FF5B37;
}
.page-link:hover {
	color: #FF5B37;
}
.page-link:focus {
	box-shadow: unset;
}
.page-item.active .page-link {
	background-color: #FF5B37;
	border-color: #FF5B37;
}
.dropdown-menu {
	border: 1px solid #eff2f7;
	border-radius: .8rem;
	transform-origin: left top 0;
	background-color: #fff;
	box-shadow: 0 0 1.25rem rgba(31,45,61,.08);
}
.navbar-nav .open .dropdown-menu {
	border: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.dropdown-menu {
	font-size: 14px;
}
.card {
	background: #FFFFFF;
	border: 1px solid #E4E4E4;
	border-radius: 5px;
    margin-bottom: 30px;
	box-sizing: border-box;
}
.card-body {
    position: relative;
    padding: 1.5rem;
}
.card .card-header {
	border-color: #E4E4E4;
	background-color: #fff;
	padding: 20px 0px;
}
.card-header:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.card-footer:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #e6e6e6;
	padding: 1rem;
}
.table .thead-light th {
    color: #495057;
    background-color: #fff;
    border-color: #B9B9B9;
}
.card .card-header .card-title {
	margin-bottom: 0;
	font-weight: 600;
	font-size: 20px;
	color: #000000;
	font-family: 'Source Sans Pro', sans-serif;
}
.modal-footer.text-left {
	text-align: left;
}
.modal-footer.text-center {
	text-align: center;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #FF5B37;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today:before {
	border-bottom-color: #FF5B37;
}
.bg-info-light {
	background-color: rgba(2, 182, 179, 0.12) !important;
	color: #1db9aa !important;
}
.bg-primary-light {
	background-color: rgba(17, 148, 247, 0.12) !important;
	color: #2196f3 !important;
}
.bg-danger-light {
	background-color: rgba(242, 17, 54, 0.12) !important;
	color: #e63c3c !important;
}
.bg-warning-light {
	background-color: rgba(255, 152, 0, 0.12) !important;
	color: #f39c12 !important;
}
.bg-success-light {
	background-color: rgba(15, 183, 107, 0.12) !important;
	color: #26af48 !important;
}
.bg-purple-light {
	background-color: rgba(197, 128, 255, 0.12) !important;
	color: #c580ff !important;
}
.bg-default-light {
	background-color: rgba(40, 52, 71, 0.12) !important;
	color: #283447 !important;
}
.custom-modal .modal-title,
.custom-modal .btn {
	font-weight: 600;
}

/*--------------
	Drop file
----------------*/

.zone {
	margin: auto;
	position: relative;
	background: #FFFAFA;
	width: 100%;
	height: 50%;
	 border: 1px dashed #CACACA;
	border-radius: 10px;
	text-align: center;
	color: #000;
	z-index: 20;
	transition: all 0.3s ease-out;
}
.zone .btnCompression .active {
	background: #EB6A5A;
	color: white;
}
.zone i {
	text-align: center;
	font-size: 3em;
	color: #FF5b33;
	margin-top: 50px;
}
.zone .selectFile {
	height: 40px;
	margin: 20px auto;
	position: relative;
	width: 200px;
}
.zone label,
.zone input {
	cursor: pointer;
	display: block;
	
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	border-radius: 5px;
}
.zone label {
	
}
.zone input[type=file] {
 	opacity: 0;
}
.zone.in {
	color: white;
	border-color: white;
	background: #FFFAFA;
}
.zone.hover {
	color: gray;
	border-color: white;
	background: #FFFAFA;
	border: 2px dashed gray;
}
.zone.hover i {
 	color: #EB6A5A;
}
.zone.hover label {
	background: #fff;
	color: #EB6A5A;
}
.zone.fade {
	transition: all 0.3s ease-out;
	opacity: 1;
}

/*-----------------
	5. Modal
-----------------------*/

.modal {
	-webkit-overflow-scrolling: touch;
}
.modal-footer.text-center {
    justify-content: center;
}
.modal-footer.text-left {
    justify-content: flex-start;
}
.modal-dialog.modal-md {
    max-width: 600px;
}
.custom-modal .modal-content {
	border: 0;
    border-radius: 10px;
}
.custom-modal .modal-footer {
	border: 0;
}
.custom-modal .modal-header {
    border: 0;
    justify-content: center;
    padding: 30px 30px 0;
}
.custom-modal .modal-footer {
    border: 0;
    justify-content: center;
    padding: 0 30px 30px;
}
.custom-modal .modal-body {
    padding: 30px;
}
.custom-modal .close {
    background-color: #FF5B37;
    border-radius: 50%;
    border: 2px solid #FF5B37;
    color: #fff;
    font-size: 18px;
    height: 26px;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 26px;
    z-index: 99;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}
.custom-modal .close:hover {
	background-color: #fff;
    border: 2px solid #FF5B37;
    color: #FF5B37;
}
.custom-modal .close span {
	top: -4px;
    position: absolute;
}
.custom-modal .modal-content {
	position: relative;
	overflow: hidden;
}
.custom-modal .modal-content:before {
	content: "";
	position: absolute;
	left: -20px;
	top: -20px;
	background-color: #FF5B37;
	width: 100px;
	height: 100px;
	border-radius: 100px;
}
.custom-modal .modal-title {
	font-size: 22px;
}
.modal-backdrop.show {
    opacity: 0.4;
	-webkit-transition-duration: 400ms;
	transition-duration: 400ms;
}
.modal .card {
	box-shadow: unset;
}


/*-----------------
	17. Delete Modal
-----------------------*/

.form-header {
    text-align: center;
	margin-bottom: 30px;
}
.form-header h3 {
    color: #333;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
}
.form-header p {
    color: #929292;
    font-size: 15px;
    margin-bottom: 0;
}
.modal-btn > .row {
	margin-left: -8px;
	margin-right: -8px;
}
.modal-btn > .row > div {
	padding-left: 8px;
	padding-right: 8px;
}
.continue-btn {
    background-color: #fff;
    border: 1px solid #ff5b33;
    border-radius: 50px;
    color: #ff5b33;
    display: block;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px;
    text-align: center;
}
.continue-btn:hover,
.continue-btn:focus,
.continue-btn:active {
	background-color: #ff5b33;
	border: 1px solid #ff5b33;
	color: #fff;
}
.cancel-btn {
    background-color: #fff;
    border: 1px solid #ff5b33;
    border-radius: 50px;
    color: #ff5b33;
    display: block;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px;
    text-align: center;
}
.cancel-btn:hover,
.cancel-btn:focus,
.cancel-btn:active {
	background-color: #ff5b33;
	border: 1px solid #ff5b33;
	color: #fff;
}

/*-----------------
	6. Select2
-----------------------*/

.select2-container .select2-selection--single {
	border: 1px solid #D7D7D7;
	height: 46px;
	border-radius: 5px;
	font-family: 'Titillium Web', sans-serif;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 38px;
	right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #a8a6a6 transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: 2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #a8a6a6;
	border-width: 0 6px 6px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 15px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #000;
	font-size: 14px;
	font-weight: normal;
	line-height: 46px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #FF5B37;
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #ddd;
	min-height: 40px;
}

/*-----------------
	7. Nav Tabs
-----------------------*/

.nav-tabs {
	border-bottom: 1px solid #e6e6e6;
}
.card-header-tabs {
	border-bottom: 0;
	margin-left: 0;
	margin-right: 0;
}
.card-header-pills {
	margin-left: 0;
	margin-right: 0;
}
.card-header-pills li {
	margin-right: 5px;
}
.nav-tabs > li > a {
	margin-right: 0;
	color: #888;
	border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	border-color: transparent;
	color: #333;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #333;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
	background-color: #FF5B37;
	border-color: #FF5B37;
	color: #fff;
}
.tab-content {
	padding-top: 20px;
}
.nav-tabs .nav-link {
	border-radius: 0;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	background-color: #eee;
	border-color: transparent;
	color: #333;
}
.nav-tabs.nav-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #333;
	padding: 10px 30px;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
	background-color: #FF5B37;
	border-color: #FF5B37;
	color: #fff;
	padding: 10px 30px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
	border-radius: 50px;
}
.nav-tabs-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li+li > a {
	margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
	border-top-color: #FF5B37;
}
.nav-tabs.nav-tabs-bottom > li {
	margin-bottom: -1px;
}
.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 2px;
	border-color: transparent;
	border-bottom-color: #FF5B37;
	background-color: transparent;
	transition: none 0s ease 0s;
	-moz-transition: none 0s ease 0s;
	-o-transition: none 0s ease 0s;
	-transition: none 0s ease 0s;
	-webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
	background-color: #fafafa;
	border: 0;
}
.nav-tabs.nav-tabs-solid > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
	background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
	background-color: #f5f5f5;
	border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}

/*-----------------
	8. Components
-----------------------*/

.section-header {
	margin-bottom: 1.875rem;
}
.section-header .section-title {
	color: #333;
}
.line {
	background-color: #FF5B37;
	height: 2px;
	margin: 0;
	width: 60px;
}
.comp-buttons .btn {
	margin-bottom: 5px;
}
.pagination-box .pagination {
	margin-top: 0;
}
.comp-dropdowns .btn-group {
	margin-bottom: 5px;
}
.progress-example .progress {
	margin-bottom: 1.5rem;
}
.progress-xs {
	height: 4px;
}
.progress-sm {
	height: 15px;
}
.progress.progress-sm {
	height: 6px;
}
.progress.progress-md {
	height: 8px;
}
.progress.progress-lg {
	height: 18px;
}
.row.row-sm {
	margin-left: -3px;
	margin-right: -3px;
}
.row.row-sm > div {
	padding-left: 3px;
	padding-right: 3px;
}
.avatar {
	position: relative;
	display: inline-block;
	width: 3rem;
	height: 3rem
}
.avatar > img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}
.avatar-title {
	width: 100%;
	height: 100%;
	background-color: #FF5B37;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.avatar-away:before,
.avatar-offline:before,
.avatar-online:before {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 25%;
	height: 25%;
	border-radius: 50%;
	content: '';
	border: 2px solid #fff;
}
.avatar-online:before {
	background-color: #22cc62;
}
.avatar-offline:before {
	background-color: #ef3737;
}
.avatar-away:before {
	background-color: #ffbc34;
}
.avatar .border {
	border-width: 3px !important;
}
.avatar .rounded {
	border-radius: 6px !important;
}
.avatar .avatar-title {
	font-size: 18px;
}
.avatar-xs {
	width: 1.65rem;
	height: 1.65rem;
}
.avatar-xs .border {
	border-width: 2px !important;
}
.avatar-xs .rounded {
	border-radius: 4px !important;
}
.avatar-xs .avatar-title {
	font-size: 12px;
}
.avatar-xs.avatar-away:before,
.avatar-xs.avatar-offline:before,
.avatar-xs.avatar-online:before {
	border-width: 1px;
}
.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
}
.avatar-sm .border {
	border-width: 3px !important;
}
.avatar-sm .rounded {
	border-radius: 4px !important;
}
.avatar-sm .avatar-title {
	font-size: 15px;
}
.avatar-sm.avatar-away:before,
.avatar-sm.avatar-offline:before,
.avatar-sm.avatar-online:before {
	border-width: 2px;
}
.avatar-lg {
	width: 3.75rem;
	height: 3.75rem;
}
.avatar-lg .border {
	border-width: 3px !important;
}
.avatar-lg .rounded {
	border-radius: 8px !important;
}
.avatar-lg .avatar-title {
	font-size: 24px;
}
.avatar-lg.avatar-away:before,
.avatar-lg.avatar-offline:before,
.avatar-lg.avatar-online:before {
	border-width: 3px;
}
.avatar-xl {
	width: 5rem;
	height: 5rem;
}
.avatar-xl .border {
	border-width: 4px !important;
}
.avatar-xl .rounded {
	border-radius: 8px !important;
}
.avatar-xl .avatar-title {
	font-size: 28px;
}
.avatar-xl.avatar-away:before,
.avatar-xl.avatar-offline:before,
.avatar-xl.avatar-online:before {
	border-width: 4px;
}
.avatar-xxl {
	width: 5.125rem;
	height: 5.125rem;
}
.avatar-xxl .border {
	border-width: 6px !important;
}
.avatar-xxl .rounded {
	border-radius: 8px !important;
}
.avatar-xxl .avatar-title {
	font-size: 30px;
}
.avatar-xxl.avatar-away:before,
.avatar-xxl.avatar-offline:before,
.avatar-xxl.avatar-online:before {
	border-width: 4px;
}
.avatar-group {
	display: inline-flex;
}
.avatar-group .avatar + .avatar {
	margin-left: -.75rem;
}
.avatar-group .avatar-xs + .avatar-xs {
	margin-left: -.40625rem;
}
.avatar-group .avatar-sm+.avatar-sm {
	margin-left: -.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
	margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
	margin-left: -1.28125rem;
}
.avatar-group .avatar:hover {
	z-index: 1;
}
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
    border: none;
    background: transparent;
}
.close {
	background-color: #FF5B37;
    border-radius: 50%;
    border: 2px solid #FF5B37;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    height: 26px;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 26px;
    z-index: 99;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}
.close:hover {
	background: #fff;
	color: #FF5B37;
	border: 2px solid #FF5B37;
}

/*-----------------
	9. Header
-----------------------*/

.admin-header {
	background: #fff;
	border-bottom: 1px solid #EEF1FE;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 3;
	height: 60px;
	border-radius: 0;
}
.admin-header .header-left {
	float: left;
	height: 60px;
	padding: 0 20px;
	position: relative;
	width: 240px;
	z-index: 1;
	transition: all 0.2s ease-in-out;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #FFF8F7;
}
.admin-header .header-left .logo {
	display: inline-block;
	line-height: 60px;
}
.admin-header .header-left .logo img {
	max-height: 45px;
	width: auto;
}
.header-left .logo.logo-small {
	display: none;
}
.admin-header .dropdown-menu > li > a {
	position: relative;
}
.admin-header .dropdown-toggle:after {
	display: none;
}
.admin-header .has-arrow .dropdown-toggle:after {
	border: 0;
	content: '';
	background-image: url(../../assets/img/down-arrow.png);
	background-repeat: no-repeat;
	background-size: cover;
	height: 12px;
	display: inline-block;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(0deg);
	-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	width: 12px;
	vertical-align: -2px;
	display: none;
}
.admin-header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-180deg);
	-transform: rotate(-180deg);
	transform: rotate(-180deg);
	vertical-align: 2px;
}
.user-menu {
	float: right;
	margin: 0;
	position: relative;
	z-index: 99;
	display: flex !important;
	padding-left: 20px;
}
.user-menu.nav > li > a {
	color: #333;
	font-size: 14px;
	line-height: 58px;
	padding: 0 15px;
	height: 60px;
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
	color: #000;
}
.admin-user-img {
	display: inline-block;
	margin-right: 3px;
	position: relative;
}
.admin-user-img img{
	width: 30px;
    border-radius: 50%;
}
.dropdown-item img{
	margin-right: 5px;
}
.user-menu.nav > li > a.mobile_btn {
	border: 0;
	position: relative;
	padding: 0;
	margin: 0;
	cursor: pointer
}
.user-menu .dropdown-menu {
	min-width: 200px;
	padding: 0;
}
.user-menu .dropdown-menu .dropdown-item {
	display: flex;
	align-items: center;
	padding: 10px 15px;
}
.user-menu .dropdown-menu .dropdown-item:hover {
	color: #FF5B37;
	background-color: transparent;
}
.admin-header .dropdown-menu > li > a:focus,
.admin-header .dropdown-menu > li > a:hover {
	background-color: #FF5B37;
	color: #fff;
}
.admin-header .dropdown-menu > li > a:focus i,
.admin-header .dropdown-menu > li > a:hover i {
	color: #fff;
}
.admin-header .dropdown-menu > li > a {
	padding: 10px 18px;
}
.admin-header .dropdown-menu > li > a i {
	color: #FF5B37;
	margin-right: 10px;
	text-align: center;
	width: 18px;
}
.admin-header .user-menu .dropdown-menu > li > a i {
	color: #FF5B37;
	font-size: 16px;
	margin-right: 10px;
	min-width: 18px;
	text-align: center;
}
.admin-header .user-menu .dropdown-menu > li > a:focus i,
.admin-header .user-menu .dropdown-menu > li > a:hover i {
	color: #fff;
}
.mobile_btn {
	display: none;
	float: left;
}
.slide-nav .sidebar {
	margin-left: 0;
}
.menu-title {
	color: #FF5B37;
	display: block;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 5px;
	padding: 0 25px;
}
.sidebar-overlay {
	background-color: rgba(0, 0, 0, 0.6);
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 60px;
	width: 100%;
	z-index: 1000;
}
.sidebar-overlay.opened {
	display: block;
}
html.menu-opened {
	overflow: hidden;
}
html.menu-opened body {
	overflow: hidden;
}
.top-nav-dropdown {
	border: 1px solid #EDEDED;
	box-sizing: border-box;
	box-shadow: 0px 4px 15px 1px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 20px;
	position: absolute;
	left: -25%;
	top: 120%;
	width: 400px;
	opacity: 0;
	transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	font-size: 14px;
	background-color: #fff;
	z-index: 99;
	visibility: hidden;
}
.top-nav-search .form-control:focus + .top-nav-dropdown {
	opacity: 1;
	visibility: visible;
	transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}
.top-nav-dropdown h5 {
	font-size: 18px;
	font-weight: 600;
	color: #333;
}
.top-nav-dropdown .row + .row {
	margin-top: 20px;
}
.top-nav-dropdown .date-col {
	background-image: url(../../assets/img/date-icon.png);
	background-size: 16px;
	background-repeat: no-repeat;
	background-position: 90% 50%;
}
.top-nav-dropdown .clear-btn {
	color: #939393;
}
.top-nav-dropdown .btn.search-btn {
	color: #fff;
	background-color: #131135;
	border-radius: 5px;
	width: inherit;
}

/*-----------------
	10. Chat
-----------------------*/

.chat-window {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 1.875rem;
    box-shadow: 0 0 13px 0 rgba(82,63,105,0.05);
    border-radius: 20px;
    overflow: hidden;
}
.chat-window .chat-cont-left {
    border-right: 1px solid #e5e5e5;
    flex: 0 0 35%;
    left: 0;
    max-width: 35%;
    position: relative;
    z-index: 4;
}
.chat-window .chat-cont-left .chat-header {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    color: #324148;
    display: flex;
    height: 72px;
    justify-content: space-between;
    padding: 0 15px;
}
.chat-window .chat-cont-left .chat-header span {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}
.chat-window .chat-cont-left .chat-header .chat-compose {
    color: #8a8a8a;
    display: inline-flex;
}
.chat-window .chat-cont-left .chat-search {
    background-color: #f5f5f6;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    width: 100%;
}
.chat-window .chat-cont-left .chat-search .input-group {
    width: 100%;
}
.chat-window .chat-cont-left .chat-search .input-group .form-control {
    background-color: #fff;
    border-radius: 50px;
    padding-left: 36px;
}
.chat-window .chat-cont-left .chat-search .input-group .form-control:focus {
    border-color: #ccc;
    box-shadow: none;
}
.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend {
    align-items: center;
    bottom: 0;
    color: #666;
    display: flex;
    left: 15px;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 4;
}
.chat-window .chat-scroll {
    max-height: calc(100vh - 255px);
    overflow-y: auto;
}
.chat-window .chat-cont-left .chat-users-list {
    background-color: #fff;
}
.chat-window .chat-cont-left .chat-users-list a.media {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    transition: all 0.2s ease 0s;
}
.chat-window .chat-cont-left .chat-users-list a.media:last-child {
	border-bottom: 0;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap {
    margin-right: 15px;
    position: relative;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
    height: 45px;
    width: 45px;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .status {
	bottom: 7px;
	height: 10px;
	right: 4px;
	position: absolute;
	width: 10px;
	border: 2px solid #fff;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-body {
    display: flex;
    justify-content: space-between;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
    color: #333;
    text-transform: capitalize;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    color: #8a8a8a;
    font-size: 14px;
    line-height: 24px;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:last-child {
    text-align: right;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:last-child .last-chat-time {
    color: #8a8a8a;
    font-size: 13px;
}
.chat-window .chat-cont-left .chat-users-list a.media:hover {
	background-color: #f5f5f6;
}
.chat-window .chat-cont-left .chat-users-list a.media.read-chat .media-body > div:last-child .last-chat-time {
	color: #8a8a8a;
}
.chat-window .chat-cont-left .chat-users-list a.media.active {
	background-color: #f5f5f6;
}
.chat-window .chat-cont-right {
    flex: 0 0 65%;
    max-width: 65%;
}
.chat-window .chat-cont-right .chat-header {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    height: 72px;
    justify-content: space-between;
    padding: 0 15px;
}
.chat-window .chat-cont-right .chat-header .back-user-list {
    display: none;
    margin-right: 5px;
    margin-left: -7px;
}
.chat-window .chat-cont-right .chat-header .media {
	align-items: center;
}
.chat-window .chat-cont-right .chat-header .media .media-img-wrap {
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 15px;
}
.chat-window .chat-cont-right .chat-header .media .media-img-wrap .avatar {
	height: 50px;
	width: 50px;
}
.chat-window .chat-cont-right .chat-header .media .media-img-wrap .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    position: absolute;
    right: 3px;
    width: 10px;
}
.chat-window .chat-cont-right .chat-header .media .media-body .user-name {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}
.chat-window .chat-cont-right .chat-header .media .media-body .user-status {
    color: #666;
    font-size: 14px;
}
.chat-window .chat-cont-right .chat-header .chat-options {
    display: flex;
}
.chat-window .chat-cont-right .chat-header .chat-options > a {
    align-items: center;
    border-radius: 50%;
    color: #8a8a8a;
    display: inline-flex;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px;
}
.chat-window .chat-cont-right .chat-body {
    background-color: #f5f5f6;
}
.chat-window .chat-cont-right .chat-body ul.list-unstyled {
    margin: 0 auto;
    padding: 15px;
    width: 100%;
}
.chat-window .chat-cont-right .chat-body .media .avatar {
    height: 30px;
    width: 30px;
}
.chat-window .chat-cont-right .chat-body .media .media-body {
	margin-left: 20px;
}
.chat-window .chat-cont-right .chat-body .media .media-body .msg-box > div {
	padding: 10px 15px;
	border-radius: .25rem;
	display: inline-block;
	position: relative;
}
.chat-window .chat-cont-right .chat-body .media .media-body .msg-box > div p {
    color: #333;
    margin-bottom: 0;
}
.chat-window .chat-cont-right .chat-body .media .media-body .msg-box + .msg-box {
	margin-top: 5px;
}
.chat-window .chat-cont-right .chat-body .media.received {
	margin-bottom: 20px;
}
.chat-window .chat-cont-right .chat-body .media:last-child {
	margin-bottom: 0;
}
.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box > div {
	background-color: #fff;
}
.chat-window .chat-cont-right .chat-body .media.sent {
    margin-bottom: 20px;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 0;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
    background-color: #e3e3e3;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div p {
    color: #333;
}
.chat-window .chat-cont-right .chat-body .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}
.chat-window .chat-cont-right .chat-body .chat-date:before {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin-right: 28px;
    position: absolute;
    right: 50%;
    top: 50%;
    width: 100%;
}
.chat-window .chat-cont-right .chat-body .chat-date:after {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    left: 50%;
    margin-left: 28px;
    position: absolute;
    top: 50%;
    width: 100%;
}
.chat-window .chat-cont-right .chat-footer {
    background-color: #fff;
    border-top: 1px solid #e0e3e4;
    padding: 10px 15px;
    position: relative;
}
.chat-window .chat-cont-right .chat-footer .input-group {
    width: 100%;
}
.chat-window .chat-cont-right .chat-footer .input-group .form-control {
    background-color: #f5f5f6;
    border: none;
    border-radius: 50px;
}
.chat-window .chat-cont-right .chat-footer .input-group .form-control:focus {
    background-color: #f5f5f6;
    border: none;
    box-shadow: none;
}
.chat-window .chat-cont-right .chat-footer .input-group .input-group-prepend .btn, 
.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn {
    background-color: transparent;
    border: none;
    color: #9f9f9f;
}
.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn.msg-send-btn {
    background-color: #FF5B37;
    border-color: #FF5B37;
    border-radius: 50%;
    color: #fff;
    margin-left: 10px;
}
.msg-typing {
	width: auto;
	height: 24px;
	padding-top: 8px
}
.msg-typing span {
	height: 8px;
	width: 8px;
	float: left;
	margin: 0 1px;
	background-color: #a0a0a0;
	display: block;
	border-radius: 50%;
	opacity: .4
}
.msg-typing span:nth-of-type(1) {
	animation: 1s blink infinite .33333s
}
.msg-typing span:nth-of-type(2) {
	animation: 1s blink infinite .66666s
}
.msg-typing span:nth-of-type(3) {
	animation: 1s blink infinite .99999s
}
.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box {
	position: relative;
}
.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box:first-child:before {
    border-bottom: 6px solid transparent;
    border-right: 6px solid #fff;
    border-top: 6px solid transparent;
    content: "";
    height: 0;
    left: -6px;
    position: absolute;
    right: auto;
    top: 8px;
    width: 0;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box {
    padding-left: 50px;
    position: relative;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box:first-child:before {
	border-bottom: 6px solid transparent;
	border-left: 6px solid #e3e3e3;
	border-top: 6px solid transparent;
    content: "";
    height: 0;
    left: auto;
    position: absolute;
    right: -6px;
    top: 8px;
    width: 0;
}
.chat-msg-info {
    align-items: center;
    display: flex;
    clear: both;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
}
.chat-msg-info li {
    font-size: 13px;
    padding-right: 16px;
    position: relative;
}
.chat-msg-info li:not(:last-child):after {
	position: absolute;
	right: 8px;
	top: 50%;
	content: '';
	height: 4px;
	width: 4px;
	background: #d2dde9;
	border-radius: 50%;
	transform: translate(50%, -50%)
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    background: #aaa;
}
.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box > div .chat-time {
    color: rgba(50, 65, 72, 0.4);
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-time {
    color: rgba(50, 65, 72, 0.4);
}
.chat-msg-info li a {
	color: #777;
}
.chat-msg-info li a:hover {
	color: #2c80ff
}
.chat-seen i {
	color: #00d285;
	font-size: 16px;
}
.chat-msg-attachments {
	padding: 4px 0;
	display: flex;
	width: 100%;
	margin: 0 -1px
}
.chat-msg-attachments > div {
	margin: 0 1px
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info {
    flex-direction: row-reverse;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-attachments {
	flex-direction: row-reverse
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info li {
    padding-left: 16px;
    padding-right: 0;
    position: relative;
}
.chat-attachment img {
    max-width: 100%;
}
.chat-attachment {
	position: relative;
	max-width: 130px;
	overflow: hidden;
}
.chat-attachment {
	border-radius: .25rem;
}
.chat-attachment:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #000;
	content: "";
	opacity: 0.4;
	transition: all .4s;
}
.chat-attachment:hover:before {
	opacity: 0.6;
}
.chat-attach-caption {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	color: #fff;
	padding: 7px 15px;
	font-size: 13px;
	opacity: 1;
	transition: all .4s;
}
.chat-attach-download {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all .4s;
	color: #fff;
	width: 32px;
	line-height: 32px;
	background: rgba(255, 255, 255, 0.2);
	text-align: center;
}
.chat-attach-download:hover {
	color: #495463;
	background: #fff;
}
.chat-attachment:hover .chat-attach-caption {
	opacity: 0;
}
.chat-attachment:hover .chat-attach-download {
	opacity: 1;
}
.chat-attachment-list {
	display: flex;
	margin: -5px;
}
.chat-attachment-list li {
	width: 33.33%;
	padding: 5px;
}
.chat-attachment-item {
	border: 5px solid rgba(230, 239, 251, 0.5);
	height: 100%;
	min-height: 60px;
	text-align: center;
	font-size: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div:hover .chat-msg-actions {
	opacity: 1;
}
.chat-msg-actions {
	position: absolute;
	left: -30px;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	transition: all .4s;
	z-index: 2;
}
.chat-msg-actions > a {
    padding: 0 10px;
    color: #495463;
    font-size: 24px;
}
.chat-msg-actions > a:hover {
	color: #2c80ff;
}

@keyframes blink {
	50% {
		opacity: 1
	}
}
.btn-file {
    align-items: center;
    display: inline-flex;
    font-size: 20px;
    justify-content: center;
    overflow: hidden;
    padding: 0 0.75rem;
    position: relative;
    vertical-align: middle;
}
.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

/*-----------------
	11. Search
-----------------------*/

.top-nav-search {
	float: left;
	margin-left: 20px;
}
.top-nav-search form {
	margin-top: 10px;
	position: relative;
	width: 274px;
}
.top-nav-search .form-control {
	border: 0;
    background: #FFFFFF;
	border: 1px solid #E4E4E4;
    border-radius: 5px;
    height: 35px;
    padding: 10px 15px 10px 35px;
    width: 100%;
    font-size: 14px;
    box-shadow: none;
}
.top-nav-search .form-control::placeholder {
	color: #7E84A3;
}
.top-nav-search .btn {
    background-color: transparent;
    border-color: transparent;
    color: #7E84A3;
    padding: 5px 10px;
    position: absolute;
    left: 0;
    top: 0;
    min-height: 35px;
    font-size: 16px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.top-nav-search.active form {
	display: block;
	left: 0;
	position: absolute;
}

/*-----------------
	12. Sidebar
-----------------------*/

.sidebar {
	background-color: #FFF8F7; 
	bottom: 0;
	left: 0;
	margin-top: 0;
	position: fixed;
	top: 60px;
	transition: all 0.2s ease-in-out 0s;
	width: 240px;
	z-index: 1001;
	overflow-y: hidden;
}
.sidebar.opened {
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.sidebar-inner {
	height: 100%;
	min-height: 100%;
	transition: all 0.2s ease-in-out 0s;
} 
.sidebar-menu {
	padding: 15px;
}
.sidebar-menu ul {
	font-size: 15px;
	list-style-type: none;
	margin: 0;
	padding: 15px 0;
	position: relative;
}
.sidebar-menu li a {
	color: #131523;
	display: block;
	font-size: 15px;
	height: auto;
	padding: 10px 15px;
}
.sidebar-menu li a:hover {
	color: #FF5B37;
}
.sidebar-menu > ul > li > a:hover {
	background-color: rgba(118, 56, 255, 0.05);
    color: #FF5B37; 
}
.sidebar-menu li.active > a {
    color: #FF5B37;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 6%);
    border-radius: 5px;
}
.menu-title {
	color: #FF5B37;
	display: flex;
	font-size: 14px;
	opacity: 1;
	padding: 5px 15px;
	white-space: nowrap;
}
.menu-title > i {
	float: right;
	line-height: 40px;
}
.sidebar-menu li.menu-title a {
	color: #ff5b33;
	display: inline-block;
	margin-left: auto;
	padding: 0;
}
.sidebar-menu li.menu-title a.btn {
	color: #fff;
	display: block;
	float: none;
	font-size: 15px;
	margin-bottom: 15px;
	padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
    color: #FF5B37;
}
.mobile_btn {
	display: none;
	float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
	transition: all 0.2s ease-in-out 0s;
	display: inline-block;
	margin-left: 10px;
	white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
	margin-left: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
	margin-left: auto;
}
.sidebar-menu ul ul a {
	display: block;
	font-size: 15px;
	padding: 7px 10px 7px 45px;
	position: relative;
}
.sidebar-menu ul ul a span {
	float: right;
	color: #FF9800;
}
.sidebar-menu ul ul {
	display: none;
}
.sidebar-menu ul ul ul a {
	padding-left: 65px;
}
.sidebar-menu ul ul ul ul a {
	padding-left: 85px;
}
.sidebar-menu > ul > li {
	margin-bottom: 3px;
	position: relative;
}
.sidebar-menu > ul > li:last-child {
	margin-bottom: 25px;
}
.sidebar-menu .menu-arrow {
	-webkit-transition: -webkit-transform 0.15s;
	-o-transition: -o-transform 0.15s;
	transition: transform .15s;
	position: absolute;
	right: 15px;
	display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
	text-rendering: auto;
	line-height: 40px;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: translate(0, 0);
	-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	line-height: 18px;
	top: 11px;
}
.sidebar-menu .menu-arrow:before {
	content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
	-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
.sidebar-menu ul ul a .menu-arrow {
	top: 10px;
}
.sidebar-menu > ul > li > a {
	align-items: center; 
	display: flex;
	justify-content: flex-start;
	padding: 10px 15px;
	position: relative;
	transition: all 0.2s ease-in-out 0s;
	border-radius: 5px;
}
.sidebar-menu > ul > li > a svg {
    width: 18px;
}
.sidebar-menu ul li a i {
	display: inline-block;
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	vertical-align: middle;
	width: 20px;
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li.menu-title a i {
	font-size: 16px !important;
	margin-right: 0;
	text-align: right;
	width: auto;
}
.sidebar-menu li a > .badge {
	color: #fff;
}

/*-----------------
	13. Calendar
-----------------------*/

.calendar-events {
    border: 1px solid transparent;
    cursor: move;
    padding: 10px 15px;
}
.calendar-events:hover {
	border-color: #e9e9e9;
    background-color: #fff;
}
.calendar-events i {
    margin-right: 8px;
}
.calendar {
	float: left;
	margin-bottom: 0;
}
.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5rem;
}
.none-border .modal-footer {
	border-top: none;
}
.fc-toolbar h2 {
	font-size: 18px;
	font-weight: 600;
	font-family: 'Roboto', sans-serif;
	line-height: 30px;
	text-transform: uppercase;
}
.fc-day-grid-event .fc-time {
	font-family: 'Roboto', sans-serif;
}
.fc-day {
	background: #fff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
	z-index: 0;
}
.fc th.fc-widget-header {
	background: #eeeeee;
	font-size: 14px;
	line-height: 20px;
	padding: 10px 0;
	text-transform: uppercase;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #f3f3f3;
}
.fc-basic-view .fc-day-number, 
.fc-basic-view .fc-week-number {
    padding: 2px 5px;
}
.fc-button {
	background: #f1f1f1;
	border: none;
	color: #797979;
	text-transform: capitalize;
	box-shadow: none !important;
	border-radius: 3px !important;
	margin: 0 3px !important;
	padding: 6px 12px !important;
	height: auto !important;
}
.fc-text-arrow {
	font-family: inherit;
	font-size: 16px;
}
.fc-state-hover {
	background: #f3f3f3;
}
.fc-state-highlight {
	background: #f0f0f0;
}
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: #FF5B37 !important;
	color: #fff !important;
	text-shadow: none !important;
}
.fc-cell-overlay {
	background: #f0f0f0;
}
.fc-unthemed .fc-today {
	background: #fff;
}
.fc-event {
	border-radius: 2px;
	border: none;
	color: #fff !important;
	cursor: move;
	font-size: 13px;
	margin: 1px 7px;
	padding: 5px 5px;
	text-align: center;
}
.fc-basic-view td.fc-week-number span {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.fc-basic-view td.fc-day-number {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.event-form .input-group .form-control {
	height: 40px;
}
.submit-section {
	text-align: center;
	margin-top: 40px;
}
.submit-btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

/*-----------------
	14. Inbox
-----------------------*/

.dropdown-action {
	margin-bottom: 5px;
}
.dropdown-action .dropdown-toggle:after {
	display: none;
}
.action-icon {
    color: #333;
    font-size: 18px;
    display: inline-block;
}
.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
	cursor: pointer;
}
.mail-list {
	list-style: none;
	padding: 0;
}
.mail-list > li > a {
	color: #333;
	display: block;
	padding: 10px;
}
.mail-list > li.active > a {
	color: #FF5B37;
	font-weight: bold;
}
.unread .name,
.unread .subject,
.unread .mail-date {
	color: #000;
	font-weight: 600;
}
.table-inbox .starred.fas.fa-star {
	color: #ffd200;
}
.table.table-inbox > tbody > tr > td,
.table.table-inbox > tbody > tr > th,
.table.table-inbox > tfoot > tr > td,
.table.table-inbox > tfoot > tr > th,
.table.table-inbox > thead > tr > td,
.table.table-inbox > thead > tr > th {
    border-bottom: 1px solid #f2f2f2;
    border-top: 0;
}
.table-inbox {
	font-size: 15px;
	margin-bottom: 0;
}
.table.table-inbox thead {
	background-color: #fff;
}
.note-editor.note-frame {
	border: 1px solid #ddd;
	box-shadow: inherit;
}
.note-editor.note-frame .note-statusbar {
	background-color: #fff;
}
.note-editor.note-frame.fullscreen {
	top: 60px;
}
.note-editor.note-frame .btn-light {
    background-color: #f9f9f9;
    box-shadow: unset;
    color: #333;
}
.mail-title {
	font-weight: bold;
	text-transform: uppercase;
}
.form-control.search-message {
    border-color: #ccc;
    border-radius: 4px;
    height: 38px;
    width: 180px;
}
.table-inbox tr {
	cursor: pointer;
}
table.table-inbox tbody tr.checked {
	background-color: #ffffcc;
}
.mail-label {
    font-size: 16px !important;
    margin-right: 5px;
}

/*-----------------
	15. Mail View
-----------------------*/

.attachments {
	list-style: none;
	margin: 0;
	padding: 0;
}
.attachments li {
	border: 1px solid #eee;
	float: left;
	margin-bottom: 10px;
	margin-right: 10px;
	width: 180px;
}
.attach-info {
	background-color: #f4f4f4;
	padding: 10px;
}
.attach-file {
    color: #777;
    font-size: 70px;
    padding: 10px;
    min-height: 138px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.attach-file img {
	height: auto;
	max-width: 100%;
}
.mailview-header {
	border-bottom: 1px solid #ddd;
	margin-bottom: 20px;
	padding-bottom: 15px;
}
.mailview-footer {
	border-top: 1px solid #ddd;
	margin-top: 20px;
	padding-top: 15px;
}
.mailview-footer .btn-white {
    margin-top: 10px;
    min-width: 102px;
}
.sender-img {
	float: left;
	margin-right: 10px;
	width: 40px;
}
.sender-name {
	display: block;
}
.receiver-name {
	color: #777;
}
.mail-view-title {
	font-weight: 500;
	font-size: 24px;
	margin: 0;
}
.mail-view-action {
	float: right;
}
.mail-sent-time {
	float: right;
}
.inbox-menu {
	display: inline-block;
	margin: 0 0 1.875rem;
	padding: 0;
	width: 100%;
}
.inbox-menu li {
	display: inline-block;
	width: 100%;
}
.inbox-menu li + li {
	margin-top: 2px;
}
.inbox-menu li a {
	color: #333;
	display: inline-block;
	padding: 10px 15px;
	width: 100%;
	text-transform: capitalize;
	-webkit-transition: 0.3s ease;
	-moz-transition: 0.3s ease;
	transition: 0.3s ease;
}
.inbox-menu li a i {
	font-size: 16px;
	padding-right: 10px;
	color: #878787;
}
.inbox-menu li a:hover, .inbox-menu li.active a, .inbox-menu li a:focus {
	background: rgba(33, 33, 33, 0.05);
}
.compose-btn {
	margin-bottom: 1.875rem;
}
.compose-btn a {
    font-weight: 600;
    padding: 8px 15px;
}

/*-----------------
	16. Error
-----------------------*/

.error-page {
    align-items: center;
    color: #1f1f1f;
    display: flex;
}
.error-page .main-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    width: 100%;
	min-height: unset;
}
.error-box {
    margin: 0 auto;
    max-width: 480px;
    padding: 1.875rem 0;
    text-align: center;
    width: 100%;
}
.error-box h1 {
    color: #FF5B37;
    font-size: 10em;
}
.error-box p {
    margin-bottom: 1.875rem;
}
.error-box .btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

/*-----------------
	17. Content
-----------------------*/

.main-wrapper {
	width: 100%;
	height: 100vh;
	min-height: 100vh;
}
.page-wrapper {
	margin-left: 240px;
	padding-top: 60px;
	position: relative;
	transition: all 0.4s ease;
}
.page-wrapper > .content {
	padding: 1.875rem 1.875rem 0;
} 
.page-header {
    margin-bottom: 20px;
}
.page-header .breadcrumb {
	background-color: transparent;
	color: #6c757d;
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 0;
	padding: 0;
}
.page-header .breadcrumb a {
	color: #333;
}
.page-title {
	font-size: 24px;
	font-weight: 600;
	color: #131523;
	margin-bottom: 5px;
	font-family: 'Source Sans Pro', sans-serif;
}
.add-button {
    width: 42px;
    height: 42px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #fff;
    color: #242424;
    border: 1px solid #242424;
}
.add-button:hover {
    background: #242424;
    color: #fff;
    border: 1px solid #242424;
}
.filter-btn {
    width: 42px;
    height: 42px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #FF5B37;
    border: 1px solid #ff431a;
    border-radius: 5px;
}
.filter-btn:hover, .filter-btn:focus {
    background: #ff431a;
    border: 1px solid #ff431a;
    color: #fff;
}
.filter-card .form-group {
    margin-bottom: 1.5rem;
    position: relative;
}
.filter-card .form-group label {
    position: absolute;
    background: white;
    font-size: 12px;
    left: 10px;
    top: -7px;
    padding: 0 7px;
    color: #6c757d;
	z-index: 1;
}
.filter-card .form-control {
	height: 46px;
}
.filter-card .btn {
	height: 46px;
}
.filter-card .select2-container .select2-selection--single {
    height: 46px;
}
.filter-card .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 46px;
}
.filter-card .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 46px;
}
.filter-card .select2-container--default .select2-selection--single .select2-selection__arrow b {
	margin-top: -2px;
}
.form-focus {
	height: 50px;
	position: relative;
}
.form-focus .focus-label {
	font-size: 14px;
	font-weight: 400;
	pointer-events: none;
	position: absolute;
	-webkit-transform: translate3d(0, 22px, 0) scale(1);
	-ms-transform: translate3d(0, 22px, 0) scale(1);
	-o-transform: translate3d(0, 22px, 0) scale(1);
	transform: translate3d(0, 22px, 0) scale(1);
	transform-origin: left top;
	transition: 240ms;
	left: 12px;
	top: -8px;
	z-index: 1;
	color: #b8b8b8;
	margin-bottom: 0;
}
.form-focus.focused .focus-label {
	opacity: 1;
	top: -18px;
	font-size: 12px;
	z-index: 1;
}
.form-focus .form-control:focus ~ .focus-label, 
.form-focus .form-control:-webkit-autofill ~ .focus-label {
	opacity: 1;
	font-weight: 400;
	top: -18px;
	font-size: 12px;
	z-index: 1;
}
.form-focus .form-control {
	height: 50px;
	padding: 21px 12px 6px;	
	border: 1px solid #FFE6E1;
}
.form-focus .form-control::-webkit-input-placeholder {
	color: transparent;
	transition: 240ms;
}
.form-focus .form-control:focus::-webkit-input-placeholder {
	transition: none;
}
.form-focus.focused .form-control::-webkit-input-placeholder {
	color: #bbb;
}
.form-control::-webkit-input-placeholder {
  	color: #343434;
	opacity: 1;
}
.form-control::-moz-placeholder {
  	color: #343434;
	opacity: 1;
}
.form-control:-ms-input-placeholder {
 	color: #343434;
	opacity: 1;
}
.form-control:-moz-placeholder {
  	color: #343434;
	opacity: 1;
}

.form-focus.select-focus .focus-label {
	opacity: 1;
	font-weight: 300;
	top: -20px;
	font-size: 12px;
	z-index: 1;
}
.form-focus .select2-container .select2-selection--single {
	border: 1px solid #e3e3e3;
	height: 50px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 48px;
	right: 7px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ccc transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: 0px;
	position: absolute;
	top: 50%;
	width: 0;
}
.form-focus .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ccc;
	border-width: 0 6px 6px;
}
.form-focus .select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 12px;
	padding-top: 10px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #676767;
	font-size: 14px;
	font-weight: normal;
	line-height: 38px;
}
.form-focus .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #fc6075;
}

/*-----------------
	18. Login
-----------------------*/
.btn-facebook {
    background: #3F76E4;
	border-radius: 165px;
    color: #fff;
    font-size: 14px;
	font-weight: bold;
    padding: 10px 12px;
}
.btn-google {
    background-color: #dd4b39;
	border-radius: 165px;
    color: #fff;
    font-size: 14px;
	font-weight: bold;
    padding: 10px 12px;
}
.btn-twitter {
    background: #29B2FF;
	border-radius: 165px;
    color: #fff;
    font-size: 14px;
	font-weight: bold;
    padding: 10px 12px;
}
.social-login  {
	border-bottom: 1px solid #E9E9E9;
    padding-bottom: 30px;
}	
.social-login .btn:hover, .social-login .btn:focus {
	color: #fff;
}
.login-body {
	display: table;
	height: 100vh;
	min-height: 100vh;
}
.logo-dark{
	max-width: 180px;
	margin: auto;
    display: block;
}
.login-wrapper {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    padding-top: 1.875rem;
}
.login-wrapper .loginbox {
	background-color: #fff;
	border-radius: 20px;
	display: flex;
	margin: 1rem auto;
	max-width: 600px;
	width: 100%;
}
.login-wrapper .loginbox .login-left {
	align-items: center;
	background: linear-gradient(180deg, #8BC34A, #00bcd4);
	border-radius: 6px 0 0 6px;
	flex-direction: column;
	justify-content: center;
	padding: 80px;
	width: 600px;
	display: flex;
}
.login-wrapper .loginbox .login-right {
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 0.5rem 2rem;
	width: 600px;
}
.login-wrapper .loginbox .login-right .login-right-wrap {
	max-width: 100%;
	flex: 0 0 100%;
}
.login-wrapper .loginbox .login-right h1 {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 5px;
	text-align: center;
}
.account-subtitle {
	color: #4c4c4c;
	font-size: 17px;
	margin-bottom: 1.875rem;
	text-align: center;
}

.social-login {
	text-align: center;
}
.social-login > span {
	color: #757575;
	margin-right: 8px;
}
.social-login > a {
	background-color: #ccc;
	border-radius: 4px;
	color: #fff;
	display: inline-block;
	font-size: 18px;
	height: 32px;
	line-height: 32px;
	margin-right: 6px;
	text-align: center;
	width: 32px;
}
.social-login > a:last-child {
	margin-right: 0;
}
.social-login > a.facebook {
	background-color: #4b75bd;
}
.social-login > a.google {
	background-color: #fe5240;
}
.login-or {
	color: #d4d4d4;
	padding-bottom: 25px;
	padding-top: 25px;
	position: relative;
	text-align: center;
}
.login-or p{
	font-size: 16px;
	color: #131135;
	margin-bottom: 0;
}
.or-line {
	background-color: #e5e5e5;
	height: 1px;
	margin-bottom: 0;
	margin-top: 0;
	display: block;
}
.span-or {
	background-color: #fff;
	display: block;
	left: 50%;
	margin-left: -20px;
	position: absolute;
	text-align: center;
	top: -3px;
	width: 42px;
}
.lock-user {
    margin-bottom: 20px;
    text-align: center;
}
.lock-user img {
    margin-bottom: 15px;
    width: 100px;
}
.admin-toggle-password {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    color: #757575;
	cursor: pointer;
}
.admin-toggle-password.fa-eye {
    margin-right: 1px;
}
.pass-group {
    position: relative;
}
.forgot-link {
    color: #131135;
    font-weight: 500;
    margin-top: 30px;
    font-size: 16px;
    display: inline-block;
    margin-bottom: 10px;
}
.forgot-link:hover {
	color: #ff5b37;
}
.login-right .dont-have {
    color: #131135;
	font-weight: 500;
    margin-top: 30px;
    font-size: 16px;
}
.login-right .dont-have a {
	color: #ff3115;
}
.login-right .dont-have a {
    color: #ff3115;
}
.login-right p {
    font-size: 16px;
    color: #17191A;
    margin-bottom: 20px;
}
.custom_check {
	color: #666;
	display: inline-block;
	position: relative;
	font-size: 14px;
	font-size: .9375rem;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.custom_check input {
	position: absolute;
	opacity: 0;
	cursor: pointer
}
.custom_check input:checked ~ .checkmark {
	background-color: #fff;
}
.custom_check .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid #FFE6E1;
	background-color: #fff;
	border-radius: 3px;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}
.custom_check .checkmark::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    display: none;
    left: 4px;
    top: 0;
    color: #FF5B37;
    font-size: 11px;
}
.custom_check input:checked ~ .checkmark:after {
	display: block
}
.custom_radio {
	color: #000;
	display: inline-block;
	position: relative;
	font-size: 14px;
	font-size: 0.9375rem;
	padding-left: 30px;
	margin-bottom: 0px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.radio_input .custom_radio + .custom_radio {
	margin-left: 15px;
}
.custom_radio input {
	position: absolute;
	opacity: 0
}
.custom_radio input:checked ~ .checkmark:after {
	opacity: 1
}
.custom_radio .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 1px solid #FF5B37;
	border-radius: 50%
}
.custom_radio .checkmark:after {
	display: block;
	content: "";
	position: absolute;
	opacity: 0;
	top: 3px;
	left: 3px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #FF5B37;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}
.custom-file-label::after {
	margin: 0.1rem;
	height: 41px;    
	border-radius: 5px;
	padding: 10px 10px;
	border-left: 0;
}
.custom-file-label {
	height: 48px;
	border: 1px solid #FFBE9D;
}
.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Upload";
}
.user-tabs  .nav-pills .nav-link {
	background: #FF5B37;
	border-radius: 3px;
	color: #fff;
	border: 1px solid #FF5B37;
}
.user-tabs  .nav-pills .nav-link.active, .user-tabs  .nav-pills .show>.nav-link {
    color: #FF5B37;
    background-color: #fff;
	border: 1px solid #FF5B37;
}
.login-foot .login-forgot {
	text-align: left;
}
.login-foot .dont-have {
	text-align: right;
}

/*-----------------
	19. Notifications
-----------------------*/

.notifications {
	padding: 0;
}
.notifications .notification-time {
	font-size: 12px;
	line-height: 1.35;
	color: #bdbdbd;
}
.notifications .media {
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
	border-bottom: none;
}
.notifications .media a {
	display: block;
	padding: 10px 15px;
	border-radius: 2px;
}
.notifications .media a:hover {
	background-color: #fafafa;
}
.notifications .media > .avatar {
	margin-right: 10px;
}
.notifications .media-list .media-left {
	padding-right: 8px;
}
.topnav-dropdown-header {
	border-bottom: 1px solid #eee;
	text-align: center;
}
.topnav-dropdown-header,
.topnav-dropdown-footer {
	font-size: 14px;
	height: 40px;
	line-height: 40px;
	padding-left: 15px;
	padding-right: 15px;
}
.topnav-dropdown-footer {
	border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
	display: block;
	text-align: center;
	color: #333;
}
.display-none {
	display: none;
}
.user-menu.nav > li > a .badge {
	background-color: #ef3737;
    display: block;
    font-size: 8px;
    font-weight: bold;
    min-height: 10px;
    min-width: 10px;
    color: #fff;
    position: absolute;
    right: 6px;
    top: 8px;
    border-radius: 50%;
}
.user-menu.nav > li > a > i {
	font-size: 22px;
	line-height: 60px;
	color: #7E84A3;
}
.user-menu.nav > li > a > svg {
	font-size: 1.5rem;
	line-height: 60px;
}
.noti-details {
	color: #989c9e;
	margin-bottom: 0;
}
.noti-title {
	color: #333;
}
.notifications .noti-content {
	height: 290px;
	width: 350px;
	overflow-y: auto;
	position: relative;
}
.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.notifications ul.notification-list > li {
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
	border-bottom: none;
}
.notifications ul.notification-list > li a {
	display: block;
	padding: 10px 15px;
	border-radius: 2px;
}
.notifications ul.notification-list > li a:hover {
	background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
	border: 0;
	padding: 0;
	position: relative;
}
.topnav-dropdown-header .notification-title {
	color: #333;
	display: block;
	float: left;
	font-size: 14px;
}
.topnav-dropdown-header .clear-noti {
	color: #f83f37;
	float: right;
	font-size: 12px;
	text-transform: uppercase;
}
.noti-time {
	margin: 0;
}

/*-----------------
	20. Dashboard
-----------------------*/

.dash-title {
	font-size: 15px;
	font-weight: 600;
}
.dash-widget-icon {
	align-items: center;
	border-radius: 10px;
	color: #fff;
	display: inline-flex;
	font-size: 1.875rem;
	height: 4rem;
	justify-content: center;
	text-align: center;
	width: 4rem;
}
.dash-count {
	font-size: 18px;
	margin-left: 15px;
	padding-top: 5px;
}
.dash-widget-info h3 {
	margin-bottom: 10px;
}
.dash-widget-header {
	align-items: center;
	display: flex;
}
.activity-feed {
	list-style: none;
	margin-bottom: 0;
	margin-left: 5px;
	padding: 0;
}
.activity-feed .feed-item {
	border-left: 2px solid #e4e8eb;
	padding-bottom: 19px;
	padding-left: 20px;
	position: relative;
}
.activity-feed .feed-item:last-child {
	border-color: transparent;
	padding-bottom: 0;
}
.activity-feed .feed-item:after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: -7px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #FF5B37;
}
.activity-feed .feed-item .feed-date {
	display: block;
	position: relative;
	color: #777;
	text-transform: uppercase;
	font-size: 13px;
}
.activity-feed .feed-item .feed-text {
	color: #777;
	position: relative;
}
.activity-feed .feed-item .feed-text a {
	color: #333;
	font-weight: 600;
} 
.modal-icon i {   
    font-size: 42px;   
    color: #6c757d;
}
.modal-footer {
	border-top: 0;
	padding-top: 0;
	padding-bottom: .75rem;
}
.add-remove i {
	font-size: 18px;
	cursor: pointer;
}
.wizard-card {
	position: relative;
	overflow: hidden;
}
.wizard-card .card-body {
	z-index: 2;
}
.wizard-card p, .wizard-card p a {
	margin-top: 20px;
	color: #000;
	margin-bottom:0;
}
.wizard-icon {
	padding: 40px;
	border-radius: 50%;
	background: #FFB8A8;
	position: absolute; 
	bottom: -27px;
	right: -27px;
	font-size: 60px;
	color: #FF5B37;
}
.dash-widget-icon {
	align-items: center;
    border-radius: 50%;
    color: #FF5B37;
    display: inline-flex;
    font-size: 2rem;
    height: 5rem;
    justify-content: center;
    text-align: center;
    width: 5rem;
    position: absolute;
    bottom: -15px;
    right: -10px;
	z-index: -1;
}
.welcome-dash-icon {
	align-items: center;
	border-radius: 50%;
	color: #FF5B37;
	display: inline-flex;
	font-size: 2rem;
	height: 4rem;
	justify-content: center;
	text-align: center;
	width: 4rem;
}
.list-group-horizontal>.list-group-item+.list-group-item {
	border-top-width:0;
}
.apexcharts-canvas {
	width: auto !important;
}
.apexcharts-svg.apexcharts-zoomable.hovering-zoom {
	width: 100%;
}
.apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg, .apexcharts-canvas .apexcharts-selection-icon.apexcharts-selected svg, .apexcharts-canvas .apexcharts-reset-zoom-icon.apexcharts-selected svg {
    fill: #ff5b37 !important;
}
.apexcharts-pan-icon.apexcharts-selected svg {
    stroke: #ff5b37 !important;
}
.account-balance {
	border-bottom: 1px solid #E4E4E4;
	margin-top: 10px;
	margin-bottom: 10px;
}
.account-balance p {
	margin-bottom: 10px;
}
.account-balance h6 {
	margin-bottom: 10px;
}
.desc-info {
	width: 300px !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.comp-section .card .card-header {
	padding: 20px;
}

/*-----------------
	21. Invoices
-----------------------*/

.invoice-item .invoice-logo {
    margin-bottom: 30px;
}
.invoice-item .invoice-logo img {
    width: auto;
    max-height: 52px;
}
.invoice-item .invoice-text h2 {
	color:#272b41;
	font-size:36px;
	font-weight:600;
}
.invoice-item .invoice-details {
	text-align: right;
	font-weight: 500
}
.invoice-item .invoice-details strong {
	color:#272b41
}
.invoice-item .invoice-details-two {
	text-align:left
}
.invoice-item .invoice-text {
	padding-top:42px;
	padding-bottom:36px
}
.invoice-item .invoice-text h2 {
	font-weight:400
}
.invoice-info {
	margin-bottom: 30px;
}
.invoice-info p {
	margin-bottom: 0;
}
.invoice-info.invoice-info2 {
    text-align: right;
}
.invoice-item .customer-text {
	font-size: 18px;
	color: #272b41;
	font-weight: 600;
	margin-bottom: 8px;
	display: block
}
.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
	color: #272b41;
	font-weight: 600;
	padding: 10px 20px;
	line-height: inherit
}
.invoice-table tr td,
.invoice-table-two tr td {
	font-weight: 500;
}
.invoice-table-two {
	margin-bottom:0
}
.invoice-table-two tr th,
.invoice-table-two tr td {
	border-top: 0;
}
.invoice-table-two tr td {
	text-align: right
}
.invoice-info h5 {
    font-size: 16px;
    font-weight: 500;
}
.other-info {
    margin-top: 10px;
}
.file {
	visibility: hidden;
	position: absolute;
}
.btn-group,
.btn-group-vertical {
	position: relative;
	display: -inline-flexbox;
	display: inline-flex;
	vertical-align: middle; 
}

/*-----------------
	22. Settings
-----------------------*/

.settings-menu ul {
	display: block;
	padding: 0;
}
.settings-menu ul li + li {
	margin-top: 15px;
}
.settings-menu ul li a {
    color: #455560;
    padding: 0;
    border: 0 !important;
	display: flex;
    justify-content: start;
    align-items: center;
}
.settings-menu ul li a:hover, .settings-menu ul li a:focus {
	background-color: transparent !important;
	border: 0 !important;
	color: #FF5B37;
}
.settings-menu ul li a.active {
	color: #FF5B37 !important;
	border: 0;
}
.settings-menu ul li i {
    margin-right: 10px;
    font-size: 20px;
    min-width: 25px;
}
.settings-menu-links .nav-tabs.menu-tabs {
	border: none;
	margin-bottom: 20px;
}
.nav-tabs.menu-tabs .nav-item.active .nav-link {
	color: #fff;
	background: #FF5B37;
}
.nav-tabs.menu-tabs .nav-item {
	padding-right: 10px;
	margin-bottom: 10px;
	display: inline-block;
}
.nav-tabs.menu-tabs .nav-link {
    position: relative;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: 'Titillium Web', sans-serif;
    color: #000000;
    background: #FFF5F3;
}
.nav-tabs.menu-tabs .nav-link:hover {
	color: #fff;
	background: #FF5B37;
}
.nav-tabs.menu-tabs .nav-link:focus {
	color: #fff;
}
.card .settings-card-header {
	background-color: #fff;
	border-bottom: 1px solid #E4E4E4;
	padding: 20px 20px;
}
.settings-card-header:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.card .settings-card-header .card-title {
	margin-bottom: 0;
	font-weight: 600;
	font-size: 18px;
	color: #000000;
}
.settings-form {
	margin-top: 20px;
}
.settings-form .form-control::placeholder {
	color: #B9B9B9;
}
.settings-form .form-placeholder .form-control::placeholder {
	color: #131523;
}
.settings-form textarea {
	min-height: 120px;
	border: 1px solid #D7D7D7;
	border-radius: 5px;
}
.settings-form textarea:focus {
    box-shadow: none;
    outline: 0 none;
    border-color: #D7D7D7;
}
.settings-form .note-btn {
	padding: 5px 8px;
    border: 1px solid #E4E4E4;
    color: #333;
}
.settings-form  .note-frame * {
	color: #333;
}
.note-editor.note-frame .note-editing-area .note-editable {
	height: 86px !important;
}
.star-red {
	color: #ff0000;
}
.settings-btn {
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    color: #000000;
    cursor: pointer;
    font-weight: 400;
    padding: 15px 15px;
    position: relative;
    width: 100%;
    height: 46px;
}
.choose-btn {
    background-color: #E8E8E8;
    border-color: #E8E8E8;
    font-size: 12px;
    color: #000000;
    min-height: 40px;
    padding: 11px 15px;
    text-transform: uppercase;
    position: absolute;
    z-index: 11;
}
.settings-btn .hide-input {
	position: relative;
    z-index: 1;
    cursor: pointer;
    min-height: auto;
    padding-left: 4px;
    padding-top: 0;
    line-height: 10px;
    width: 100%;
    opacity: 0;
}
.settings-btn .upload {
    position: absolute;
    z-index: 11;
    background: #FF5B37;
    border-radius: 5px;
    right: 2px;
    top: 1.5px;
    width: 41px;
    height: 41px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.settings-btn .upload-input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 310px;
    background: transparent;
}
.settings-label {
    margin-bottom: 10px;
    font-family: 'Titillium Web', sans-serif;
}
.settings-size {
	font-size: 14px;
	color: #131523;
	font-family: 'Titillium Web', sans-serif;
	font-style: italic;
	margin-top: 10px;
	margin-bottom: 0;
}
.settings-size span {
	font-weight: 700;
}
.upload-images {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    display: flex;
    margin-top: 30px;
    width: 196px;
    height: 78px;
    justify-content: space-around;
    align-items: center;
    position: relative;
}
.upload-size {
	width: 82px;
    height: 82px;
}
.upload-images img {
    max-height: 45px;
    width: auto;
}
.upload-images .btn-icon {
	color: #ff0000;
    font-size: 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    position: absolute;
    padding: 0;
    width: 20px;
    height: 20px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    right: 0px;
    top: 0px;
    justify-content: center;
    -webkit-justify-content: center;
}
.check {
	display: block;
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	position: absolute;
}
.checktoggle {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);;
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
}
.checktoggle:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(5px, -50%);
	width: 16px;
	height: 16px;
	background-color: rgba(0, 0, 0, 0.25);
	border-radius: 50%;
	transition: left 300ms ease, transform 300ms ease;
}
.check:checked + .checktoggle {
    background-color: #ff5b37;
    border: 1px solid #ff5b37;
}
.check:checked + .checktoggle:after {
	background-color: #fff;
	left: 100%;
	transform: translate(calc(-100% - 5px), -50%);
}
.onoffswitch {
	margin-left: auto;
    position: relative;
	width: 73px;
    -webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; 
	overflow: hidden; 
	cursor: pointer;
	border-radius: 20px;
	margin-bottom: 0;
}
.onoffswitch-inner {
	display: block;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    width: 200%;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    box-sizing: border-box;
    color: #fff;
    display: block;
    float: left;
    font-size: 16px;
    height: 30px;
    line-height: 32px;
    padding: 0;
    width: 50%;
}
.onoffswitch-inner:before {
	background-color: #55ce63;
    color: #fff;
    content: "ON";
    padding-left: 14px;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 14px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}
.onoffswitch-switch {
    background: #fff;
    border-radius: 20px;
    bottom: 0;
    display: block;
	height: 20px;
    margin: 5px;
    position: absolute;
    right: 43px;
    top: 0;
    transition: all 0.3s ease-in 0s;
    width: 20px;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
}
.settings-btns {
	font-family: 'Titillium Web', sans-serif;
}
.settings-btns .btn-orange {
	font-size: 16px;
	font-weight: 700;
	padding: 10px 26px;
	margin-right: 15px;
	background: #FF5B37;
	border: 1px solid #FF5B37;
	color: #fff;
	border-radius: 4px;
}
.settings-btns .btn-orange:hover {
	background: #ff431a;
	border: 1px solid #ff431a;
	color: #fff;
}
.settings-btns .btn-grey {
	font-size: 16px;
	font-weight: 700;
	padding: 10px 26px;
	background: #fff;
	color: #242424;
	border: 1px solid #242424;
	border-radius: 4px;
}
.settings-btns .btn-grey:hover {
	background: #242424;
	border: 1px solid #242424;
	color: #fff;
}
.pay-cont {
	font-size: 16px;
	font-weight: 600;
	font-family: 'Titillium Web', sans-serif;
	margin-bottom: 16px;
}
.settings-form .bootstrap-tagsinput {
    display: block;
    border-radius: 5px;
    color: #333;
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    box-shadow: unset;
    min-height: 46px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 10px;
}
.settings-form .bootstrap-tagsinput .tag {
    margin-right: 5px;
    margin-bottom: 0px;
    color: #000000;
    background: #E4E4E4 !important;
    border-radius: 0px;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 12px;
    font-family: 'Titillium Web', sans-serif;
}
.settings-form .bootstrap-tagsinput input {
	line-height: 36px;
}
.settings-form .links-cont .social-icon::after {
	content: "";
    border-top: 0.2em solid;
    border-right: 0.2em solid transparent;
    border-bottom: 0;
    border-left: 0.2em solid transparent;
    margin-left: 4px;
}
.settings-form .social-icon {
    width: 50px;
    height: 50px;
    margin-right: 6px;
    color: #fff;
    background: #FF5B37;
    border: 1px solid #FF5B37;
    border-radius: 5px;
    font-size: 24px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}
.add-links {
	background-color: #17D053;
	color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 26px;
    border-radius: 4px;
}
.add-links:hover {
	color: #fff;
	opacity: 0.8;
}
.settings-form .trash {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 46px;
	width: 46px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    background-color: #ff0100;
    color: #fff;
    position: relative;
    right: 16px;
    font-size: 20px;
}
.settings-form .trash:hover {
	color: #fff;
	opacity: 0.8;
}
.bg-pink {
    background-color: #fff8f7;
    border: 1px solid #f6d1cb;
}
.bg-pink:hover {
    background-color: #f6d1cb;
}

/*-----------------
	23. Switch Buttons
-----------------------*/

.toggle-switch {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer
}
.toggle-switch:not(.form-group) {
	margin-bottom: 0
}
.toggle-switch-input {
	position: absolute;
	z-index: -1;
	opacity: 0
}
.toggle-switch-content {
	-ms-flex: 1;
	flex: 1;
	margin-left: .5rem
}
.toggle-switch-label {
	position: relative;
	display: block;
	width: 3rem;
	height: 30px;
	background-color: #e7eaf3;
	background-clip: content-box;
	border: .125rem solid transparent;
	border-radius: 6.1875rem;
	transition: .3s
}
.toggle-switch-indicator {
	position: absolute;
	left: .125rem;
	bottom: 50%;
	width: 22px;
	height: 22px;
	background-color: #fff;
	-webkit-transform: initial;
	transform: initial;
	box-shadow: 0 3px 6px 0 rgba(140, 152, 164, .25);
	border-radius: 50%;
	-webkit-transform: translate3d(0, 50%, 0);
	transform: translate3d(0, 50%, 0);
	transition: .3s
}
.toggle-switch-input:checked + .toggle-switch-label {
	background-color: #FF5B37;
}
.toggle-switch-input:checked+.toggle-switch-label .toggle-switch-indicator {
	-webkit-transform: translate3d(1.025rem, 50%, 0);
	transform: translate3d(1.025rem, 50%, 0)
}
.toggle-switch-input.is-valid + .toggle-switch-label {
	background-color: #00c9a7
}
.toggle-switch-input.is-invalid + .toggle-switch-label {
	background-color: #ed4c78
}
.toggle-switch-input:disabled + .toggle-switch-label {
	background-color: rgba(231, 234, 243, .5)
}
.toggle-switch-input:checked:disabled + .toggle-switch-label {
	background-color: rgba(55, 125, 255, .5)
}
.toggle-switch-sm .toggle-switch-label {
	width: 2.5rem;
	height: 1.6125rem
}
.toggle-switch-sm .toggle-switch-indicator {
	width: 1.20938rem;
	height: 1.20938rem
}
.toggle-switch-sm .toggle-switch-input:checked + .toggle-switch-label .toggle-switch-indicator {
	-webkit-transform: translate3d(.81094rem, 50%, 0);
	transform: translate3d(.81094rem, 50%, 0)
}
 
/*-----------------
	24. Profile
-----------------------*/

.cal-icon {
	position: relative;
	width: 100%;
}
.cal-icon:after {
	color: #979797;
	content: "\f073";
	display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 15px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 10px;
}
.profile-cover {
    position: relative;
    padding: 1.75rem 2rem;
    border-radius: .75rem;
    height: 10rem;
}
.profile-cover-wrap {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 10rem;
    background-color: #e7eaf3;
    border-radius: .75rem;
}
.profile-cover-img {
    width: 100%;
    height: 10rem;
    -o-object-fit: cover;
    object-fit: cover;
    vertical-align: top;
    border-radius: .75rem;
}
.cover-content {
    position: relative;
    z-index: 1;
    padding: 1rem 2rem;
    position: absolute;
    bottom: 0;
    right: 0;
}
.custom-file-btn {
    position: relative;
    overflow: hidden;

}
.custom-file-btn-input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.profile-cover-avatar {
    display: -ms-flexbox;
    display: flex;
    margin: -6.3rem auto .5rem auto;
    border: 3px solid #fff;
	border-radius: 50%;
}
.profile-cover-avatar input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(19,33,68,.25);
    border-radius: 50%;
    transition: .2s;
}
.avatar-edit {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    color: #677788;
    background-color: #fff;
    border-radius: 50%;
    transition: .2s;
	box-shadow: 0 3px 6px 0 rgba(140,152,164,.25);
}
.avatar-edit svg {
	width: 18px;
}
.card-table div.table-responsive > div.dataTables_wrapper > div.row:first-child {
	padding: 1.5rem 1.5rem 0;
}
.card-table div.table-responsive > div.dataTables_wrapper > div.row:last-child {
	padding: 0 1.5rem 1.5rem;
}
#filter_inputs {
    display: none;
}
.input-label {
    display: block;
    color: #1e2022;
    font-size: .875rem;
}
.submit-section {
	text-align: center;
	margin-top: 40px;
}
.submit-btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}
.card-body-height {
    height: 28rem;
    overflow: hidden;
    overflow-y: auto;
}
.profile-cover-avatar .avatar-img {
    display: block;
    max-width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    pointer-events: none;
    border-radius: 50%;
}

/*-----------------
	25. Subscription
-----------------------*/

.active-btn {
	background: rgba(8, 142, 37, 0.03);
	min-width: 96px;
	padding: 0px 15px;
	color: #088E25;
}
.active-btn:hover {
	color: #088E25;
}
.inactive-btn {
	background: rgba(255, 0, 0, 0.03);
	min-width: 96px;
	padding: 0px 15px;
	color: #F0142F;
}
.inactive-btn:hover {
	color: #F0142F;
}
.subscription-end .btn-secondary {
    background-color: transparent;
    border: 0;
	color: #1B2559;
}
.subscription-end .btn-danger {
    background-color: transparent;
    border: 0;
	color: #F0142F;
}
.subscribe-employe {
	margin-bottom: 20px;
	border-bottom: 1px solid #F9F9F9;
}
.subscribe-employe ul {
	padding: 0;
    margin: 0;
    display: flex;
}
.subscribe-employe ul li {
	padding-bottom: 10px;
	position: relative;
}
.subscribe-employe ul .active a{
	color: #FF5B37;
}
.subscribe-employe ul li a {
	padding: 0px 20px;
	font-size: 18px;
	color: #131523;
	font-weight: 600;
}
.subscribe-employe ul .active:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	background: #ff5b37;
	bottom: 0;
    border-radius: 5px;
}
.subscribe-head {
	border-bottom: 1px solid #F9F9F9;
	padding-bottom: 10px;
}
.subscribe-head .add-button,
.subscribe-head .filter-btn {
	min-width: 141px;
	padding: 8px 15px;
	height: inherit;
	border: 0;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
}
.subscribe-head .filter-btn {
	background: #FF5B37;
}
.subscribe-head .add-button {
	background: #000000;
}

.price-plan {
	padding-top: 20px;
}
.heading-price h2 {
	font-size: 24px;
	color: #000000;
	font-weight: 700;
}
.heading-price  p {
	font-size: 16px;
	color: #7E84A3;
	font-weight: 400;
}
.basic-plan {
	background: rgba(255, 91, 55, 0.03);
	border-radius: 10px;
	padding: 20px;
}
.basic-plan h2 {
	color: #1B2559;
	font-size: 20px;
	font-weight: 700;
}
.basic-plan p {
	color: #8F9BBA;
	font-size: 14px;
	font-weight: 500;
}
.credit-list {
	margin: 0;
	padding: 0;
	margin-bottom: 27px;
}
.credit-list li {
	line-height: 48px;
	color: #1B2559;
	font-size: 14px;
	font-weight: 400;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.credit-list li i {
	margin-left: auto;
	color: #008F64;
}
.credit-project {
	padding: 20px;
}
.credit-list .wrong-credit {
	color: #F0142F;
}
.btn-plan {
	background: transparent;
	border: 0;
	min-width: 110px;
	padding: 8px 15px;
	font-weight: 700;
	font-size: 14px;
}
.btn-plan.active {
	background-color: #FF5B37;
	color: #fff;
	box-shadow: 0px 4px 34px rgba(227, 227, 227, 0.45);
	border-radius: 5px;
}
.clockfie-plan {
	background: #FFFFFF;
	border: 1px solid rgba(231, 234, 252, 0.75);
	box-shadow: 0px 4px 34px rgba(235, 235, 235, 0.25);
	border-radius: 10px;
	margin-bottom: 25px;
	padding: 7px;
	width: 100%;
	-webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}
.price-start p {
	color: #374151;
	margin-bottom: 15px;
}
.recomend {
	display: flex;
	align-items: center;
	margin-bottom: 7px;
}
.recomend h4 {
	margin-bottom: 0px;
}
.recomend a {
	font-size: 14px;
	margin-left: 6px;
}
.price-start h2 {
	font-size: 24px;
	color: #FF5B37;
	margin-bottom: 0px;
	font-weight: 700;
}
.price-start span {
	font-size: 14px;
	font-weight: 400;
	color: #8F9BBA;
}
.subscribe-now .btn-primary {
	min-width: 100%;
    padding: 11px 10px;
	background-color: #000000;
	border: 1px solid #000000;
	border-radius: 10px
}
.subscribe-now .btn-primary:hover {
	background-color: #4a4949;
	border: 1px solid #4a4949;
}
.add-plan .form-group span {
	color: red;
}
.add-plan .form-group label {
	color: #1B2559;
	font-size: 14px;
}
.custom-modal .modal-header.add-plan-head {
	padding: 20px 30px 50px;
}
.add-plan-head h4 {
	font-size: 24px;
	color: #131523;
}
.plan-btn .remove-form {
	min-width: 170px;
	padding: 10px 15px;
	background: #EEF1FE;
	border: 1px solid #EEF1FE;
	border-radius: 10px;
	color: #000000;
	margin-right: 10px;
}
.plan-btn .remove-form:focus:active {
	background: #EEF1FE;
	border: 1px solid #EEF1FE;
	color: #000000;
}
.plan-btn .save-form {
	min-width: 170px;
	padding: 10px 15px;
	background: #FF5B37;
	border: 1px solid #FF5B37;
	border-radius: 10px;
	color: #fff;
}
.price-list {
	background: #FFFFFF;
	box-shadow: 0px 4px 14px rgba(188, 188, 188, 0.25);
	border-radius: 8px;
	padding: 3px;
	max-width: 228px;
	margin: auto;
	margin-bottom: 28px;
	margin-top: 17px;
}
.employe-tables th,
.employe-tables td {
	color: #000000;
}
.table.employe-tables thead tr th {
	font-weight: 400;
}
.employe-list tbody tr td:nth-child(2) a {
	color: #FF5B37;
}
.active-name {
	color: #000;
}

/*-----------------
	26. Report
-----------------------*/

.project-report {
	padding: 0;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
}
.project-report .active-project {
	background: #000000;
	border-radius: 3px;
	text-align: center;
}
.project-report li:hover {
	background: #000000;
	border-radius: 3px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.project-report li:hover a {
	color: #fff;
}
.project-report li {
	margin-right: 6px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.project-report li:last-child {
	margin-right: 0px;
}
.project-report .active-project a {
	color: #fff;
}
.project-report li a {
	color: #000000;
	font-size: 14px;
	font-weight: 600;
	min-width: 113px;
	padding: 10px 15px;
	text-align: center;
}
.project-report li {
	min-width: 113px;
	padding: 10px 15px;
	text-align: center;
}
.down-load {
	margin-left: auto;
}
.title-group h2 {
	font-size: 19px;
	font-weight: 600;
}
.down-load .btn-primary {
	min-width: 118px;
	background: #1B2559;
	border-radius: 5px;
	color: #fff;
	border: 0;
	padding: 5px 15px;
}
.down-load .btn-primary i {
	margin-right: 9px;
}
.select-group {
	background: #FFFFFF;
	box-shadow: 0px 2px 24px rgba(196, 196, 196, 0.25);
	border-radius: 5px;
	padding: 15px;
	margin: 15px 0px 19px;
}
.select-group .form-select {
	background-color: #FBFBFB;
	border: 0;
	height: 45px;
}
.select-group .form-select:focus {
	box-shadow: none;
}
.project-chart .card-header {
	padding: 20px;
	border-bottom: 1px solid #E5F4FE;
}
.project-chart .card {
	background: #FFFFFF;
	box-shadow: 0px 4px 24px 10px rgba(231, 231, 231, 0.3);
	border: 0;
}
.page-border {
	border-bottom: 1px solid #F9F9F9;
	padding-bottom: 10px;
	margin-bottom: 33px;
}
.page-wrapper .content.report-box {
	padding-bottom: 5px;
}

/*-----------------
	27. Users
-----------------------*/

.add-user {
	background: #1B2559;
	padding: 7px 25px;
    height: inherit;
    border: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
	display: inline-flex;
    justify-content: center;
    align-items: center;
}
.add-user:hover {
	 color: #fff;
	 background: #1e3197;
}
.add-user i {
	border: 1px solid #fff;
    border-radius: 50%;
    font-size: 8px;
    width: 13px;
    height: 13px;
    padding: 2px;
	margin-right: 2px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.users-list ul li a {
	font-size: 14px;
	color: #000;
}
.users-list {
	border-bottom: 1px solid #E5F4FE;
}
.user-active {
	background: rgba(235, 235, 235, 0.25);
	padding: 10px;
}
.user-active .page-title {
    font-size: 19px;
}
.user-active p {
	color: #7E84A3;
	font-size: 14px;
}
.user-active p span {
	color: #FF5B37;
}
.export-btn {
	background: #000000;
	border-radius: 3px;
	color: #fff;
	padding: 10px 15px;
	font-size: 14px;
	padding: 7px 26px;
}
.export-btn:hover {
	background: #333;
	color: #fff;
}
.export-btn img {
	margin-right: 4px;
}
.user-active .filter-btn {
    width: 37px;
    height: 37px;
	border-radius: 3px;
}
.user-profile a {
	display: inline-flex;
	 -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.user-profile h5 a,
.content-user h5 {
	margin-bottom: 2px;
	font-size: 14px;
	font-weight: 600;
	color: #000000;
}
.user-profile h5 {
	margin-bottom: 2px;
}
.user-profile p,
.content-user p {
	font-size: 14px;
	font-weight: 400;
	color: #7E84A3;
}
.user-profile a img {
	margin-right: 10px;
}
.three-dots i {
	width: 26px;
    height: 26px;
    background: #F9F9F9;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: auto;
	font-size: 14px;
	color: #000;
}
.three-dots .dropdown-toggle::after {
	display: none;
}
.three-dots .nav-link {
	padding: 0;
}
.user-menu-list {
	background: #FFFFFF;
	border: 1px solid #F9F9F9;
	border-radius: 6px;
	padding: 20px;
	min-width: 247px;
}
.user-menu-list .dropdown-item {
	padding: 0px;
	margin-bottom: 25px;
	color: #1B2559;
}
.user-menu-list .dropdown-item:hover,
.user-menu-list .dropdown-item:focus {
	background: none;
}
.user-menu-list h4 {
	color: #FF5B37;
	font-size: 16px;
	font-weight: 600;
}
.user-menu-list .form-check label {
	margin-bottom: 0;
}
.user-menu-list .form-check {
	border-bottom: 1px solid rgba(231, 234, 252, 0.75);
	padding: 15px;
}
.email-check {
	color: #7E84A3;
}
.user-tables th, .user-tables td,
.table.user-tables thead th {
    color: #000000;
	font-size: 14px;
	font-weight: 400;
}
.user-active-btn,
.user-active-btn:hover {
	color: #088E25;
	font-size: 14px;
}
.user-inactive-btn,
.pending,
.pending:hover,
.user-inactive-btn:hover {
	color: #F38345;
}
.verify-mail .feather {
	width: 14px;
    height: 14px;
}
.user-suspended,
.user-suspended:hover {
	color: #F0142F;
	font-size: 14px;
}
.on-hold,
.on-hold:hover {
	color: #FFC700;
}
.apply-user,
.reset-user {
	width: 100%;
	display: block;
	border-radius: 4px;
	border: 0;
}
.apply-user:hover,
.reset-user:hover {
	border: 0;
}
.reset-user {
	background: rgba(231, 234, 252, 0.75);
	color: #000;
}
.user-menu-list .form-check-input:checked {
    background-color: #ff5b37;
    border-color: #ff5b37;
}
.back-user  {
	border: 1px solid #EBF0F5;
	border-radius: 4px;
	font-size: 14px;
	padding: 6px 13px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.back-user:hover {
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
	box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
}
.back-user img {
	margin-right: 7px;
}
.profile-home {
	padding: 20px;
	border: 1px solid #F9F9F9;
	border-radius: 5px;
}
.profile-information {
	margin-bottom: 40px;
}
.profile-information h3,
.profile-information p {
	font-size: 16px;
}
.profile-information h3 {
	font-weight: 600;
	color: #000;
}
.profile-information p {
	font-weight: 400;
	color: #7E84A3;
}
.basic-info h4 {
	border-bottom: 1px solid #EEF1FE;
	color: #FF5B37;
	font-size: 16px;
	font-weight: 600;
	padding-bottom: 14px;
	margin-bottom: 14px;
}
.information-list {
	padding: 0;
}
.information-list li {
	list-style: none;
	font-size: 16px;
	font-weight: 400;
	color: #7E84A3;
	margin-bottom: 20px;
}
.information-list li span{
	float: right;
	font-size: 16px;
	font-weight: 600;
	color: #000;
}
.additional-info p {
	font-size: 16px;
	font-weight: 400;
	color: #7E84A3;
	margin-bottom: 6px;
}
.additional-info h5 {
	font-size: 16px;
	font-weight: 600;
	color: #000;
	margin-bottom: 0;
}
.additional-space {
	margin-top: 50px;
}
.profile-status {
	background: rgba(172, 175, 198, 0.02);
	border-radius: 4px;
	padding: 20px;
	margin-bottom: 10px;
}
.profile-user-box {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.profile-user-box img {
	width: 51px;
	height: 51px;
	margin-right: 20px;
}
.profile-name-box h2  {
	font-size: 20px;
	font-weight: 600;
}
.profile-name-box h2 a {
	color: #000000;
}
.profile-name-box p {
	font-size: 14px;
	color: #7E84A3;
	font-weight: 400;
}
.profile-status .additional-info p,
.profile-status .additional-info h5 {
	font-size: 14px;
}
.complete-show i {
	width: 16px;
    height: 16px;
    background: #FF5B37;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: auto;
    font-size: 10px;
    color: #fff;
}
.received-btn {
	background: #EEF7F0;
	border-radius: 30px;
	color: #088E25;
	padding: 2px 15px;
	font-weight: 600;
	min-width: 116px;
	font-size: 14px;
}
.received-btn:hover {
	background: #EEF7F0;
	color: #088E25;
}
.withdraw-btn {
	background: rgba(240, 20, 47, 0.08);
	border-radius: 30px;
	padding: 2px 15px;
	font-weight: 600;
	min-width: 116px;
	font-size: 14px;
}
.withdraw-btn:hover {
	background: rgba(240, 20, 47, 0.08);
}
.exchange,
.refresh {
	width: 39px;
	height: 39px;
	border-radius: 50%;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
	margin-right: 15px;
}
.exchange {
	background: rgba(62, 69, 235, 0.12);
	color: #3E45EB;
}
.refresh {
	background: rgba(243, 131, 69, 0.09);
	color: #F38345;
}
.receive-date {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.table td h2 .receive-date span {
	margin-top: 0;
	margin: auto;
}
.name-user a {
	font-weight: 600;
	color: #000;
}
.transaction-group {
	width: 100%;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.head-transaction h4 {
	font-size: 24px;
    color: #131523;
    font-weight: 600;
    margin-bottom: 2px;
}
.head-transaction p {
	color: #FF5B37;
	font-size: 14px;
	font-weight: 400;
}
.complete-btn {
	margin-left: auto;
}
.complete-btn .btn-primary:hover {
	background: #07ad2b
}
.complete-btn .btn-primary {
	font-size: 14px;
    font-weight: 600;
    padding: 4px 23px;
	background: #088E25;
	border-radius: 5px;
	border: 0;
}
.acount-transaction .basic-info h4 {
	font-size: 14px;
	border-bottom: 0;
}
.acount-transaction .additional-info p,
.acount-transaction .additional-info h5 {
	font-size: 14px;
}
.invest-acount {
	margin: 5px 0px 30px;
}
.acount-transaction .additional-info {
	margin-bottom: 25px;
}
.top-border {
	border-top: 1px solid #D1DAE6;
}
.additional-detail h4 {
	padding: 0;
	margin: 16px 0px 12px;
}
.transaction-modal .modal-dialog {
    max-width: 700px;
}
.additional-info .active-balance {
	color: #FF5B37;
}
.transaction-modal .modal-body,
.transaction-modal .modal-header {
	padding: 20px;
}
.reset-icon,
.suspend-icon {
	background: #F5F6FA;
	border-radius: 4px;
	width: 25px;
	height: 25px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.transaction-list {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.profile-icon-group {
	margin-left: auto;
}
.transaction-list ul  li {
	padding-bottom: 20px;
}
[data-popper-placement~=bottom-end]:before {
	content: "";
    position: absolute;
    top: -12px;
    right: -12px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
}
[data-popper-placement~=top-end]:before {
	content: "";
    position: absolute;
    bottom: -12px;
    right: -12px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #fff;
}
.user-profile {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.user-profile img {
	width: 38px;
	height: 38px;
}

/*-----------------
	Pagination
-----------------------*/

.paginations li {
	list-style: none;
	display: inline-block;
	padding-right: 20px;
}
.paginations li:last-child {
	padding-right: 0;
}
.paginations li a {
	font-weight: 600;
	font-size: 14px;
	color: #161c2d;
}
.paginations li a.active {
    background: #ff5b37;
    color: #fff;
    padding: 0px 7px;
    border-radius: 50%;
}
.paginations li:hover a.active {
    color: #fff;
}
.paginations li:hover a {
    color: #ff5b37;
}
.paginations.freelancer {
	text-align: right;
}
.paginations.freelancer li:last-child {
	padding-right: 0;
}
.case-icon {
	padding: 8px;
}

/*-----------------
	28. Responsive
-----------------------*/

@media (min-width: 768px) {
	.avatar-xxl {
		width: 8rem;
		height: 8rem;
	}
	.avatar-xxl .border {
		border-width: 4px !important;
	}
	.avatar-xxl .rounded {
		border-radius: 12px !important;
	}
	.avatar-xxl .avatar-title {
		font-size: 42px;
	}
	.avatar-xxl.avatar-away:before,
	.avatar-xxl.avatar-offline:before,
	.avatar-xxl.avatar-online:before {
		border-width: 4px;
	}
}
@media (min-width: 992px) {
	#toggle_btn {
		align-items: center;
		color: #333;
		display: inline-flex;
		float: left;
		font-size: 30px;
		height: 60px;
		justify-content: center;
		margin-left: 15px;
		padding: 0 0px;
	}
	#toggle_btn:hover {
		color: #FF5B37;
	}
	.mini-sidebar .header-left .logo img {
		height: auto;
		max-height: 40px;
		width: auto;
	}
	.mini-sidebar .admin-header .header-left .logo {
		display: none;
	}
	.mini-sidebar .header-left .logo.logo-small {
		display: block;
	}
	.mini-sidebar .admin-header .header-left {
		width: 78px;
	}
	.mini-sidebar .sidebar {
		width: 78px;
	}
	.mini-sidebar.expand-menu .sidebar {
		width: 240px;
	}
	.mini-sidebar .menu-title {
		visibility: hidden;
		white-space: nowrap;
	}
	.mini-sidebar.expand-menu .menu-title {
		visibility: visible;
	}
	.mini-sidebar .menu-title a {
		visibility: hidden;
	}
	.mini-sidebar.expand-menu .menu-title a {
		visibility: visible;
	}
	.modal-open.mini-sidebar .sidebar {
		z-index: 1051;
	}
	.mini-sidebar .sidebar .sidebar-menu ul > li > a span {
		display: none;
		transition: all 0.2s ease-in-out;
		opacity: 0;
	}
	.mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
		display: inline;
		opacity: 1;
	} 
	.mini-sidebar .page-wrapper {
		margin-left: 78px;
	}
}
@media (max-width: 991.98px) {
	.login-foot .login-forgot {
		text-align: center;
	}
	.login-foot .dont-have {
		text-align: center;
	}
	.admin-header .header-left {
		position: absolute;
		width: 100%;
	}
	.admin-header .header-left .logo {
		width: 100%;
		text-align: center;
		margin-right: 0;
	}
	.add-item {
		margin-top: 10px;
	}
	.flag-nav{
		display: none;
	}
	.mobile_btn {
		color: #333;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 24px;
		height: 60px;
		left: 0;
		line-height: 60px;
		padding: 0 15px;
		position: absolute;
		text-align: center;
		top: 0;
		z-index: 10;
	}
	#toggle_btn {
		display: none;
	}
	.top-nav-search {
		display: none;
	}
	.login-wrapper .loginbox .login-left {
		padding: 80px 50px;
		width: 50%;
	}
	.login-wrapper .loginbox .login-right {
		padding: 50px;
		width: 100%;
	}
	.sidebar {
		margin-left: -225px;
		width: 225px;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		z-index: 1041;
	}
	.page-wrapper {
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
	.chat-window .chat-scroll {
		max-height: calc(100vh - 255px);
	}
	.chat-window .chat-cont-left,
	.chat-window .chat-cont-right {
		flex: 0 0 100%;
		max-width: 100%;
		transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
		width: 100%;
	}
	.chat-window .chat-cont-left {
		border-right: 0;
	}
	.chat-window .chat-cont-right {
		position: absolute;
		right: calc(-100% + -1.875rem);
		top: 0;
	}
	.chat-window .chat-cont-right .chat-header {
		justify-content: start;
	}
	.chat-window .chat-cont-right .chat-header .back-user-list {
		display: block;
	}
	.chat-window .chat-cont-right .chat-header .chat-options {
		margin-left: auto;
	}
	.chat-window.chat-slide .chat-cont-left {
		left: calc(-100% + -1.875rem);
	}
	.chat-window.chat-slide .chat-cont-right {
		right: 0;
	}
	.sidebar {
		top: 60px;
	}
	.subscribe-employe ul li a {
		font-size: 16px;
	}
	.select-group .form-select {
		margin-bottom: 10px;
	}
	.profile-user-box img {
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}
	.profile-name-box h2 {
		font-size: 18px;
		margin-bottom: 0;
	}
	.additional-info {
		margin-bottom: 15px;
	}
}
@media (max-width: 767.98px) {
	body {
		font-size: 0.9375rem;
	}
	h1,
	.h1 {
		font-size: 2rem;
	}
	h2,
	.h2 {
		font-size: 1.75rem;
	}
	h3,
	.h3 {
		font-size: 1.5rem;
	}
	h4,
	.h4 {
		font-size: 1.125rem;
	}
	h5,
	.h5 {
		font-size: 1rem;
	}
	h6,
	.h6 {
		font-size: 0.875rem;
	}
	.admin-header .has-arrow .dropdown-toggle:after {
		display: none;
	}
	.user-menu.nav > li > a > span:not(.admin-user-img) {
		display: none;
	}
	.navbar-nav .open .dropdown-menu {
		float: left;
		position: absolute;
	}
	.navbar-nav.user-menu .open .dropdown-menu {
		left: auto;
		right: 0;
	}
	.admin-header .header-left {
		padding: 0 0px;
	}
	.admin-header .header-left .logo {
		display: none;
	}
	.header-left .logo.logo-small {
		display: inline-block;
	}
	.login-wrapper .loginbox .login-left {
		display: none;
	}
	.login-wrapper .loginbox {
		max-width: 450px;
		min-height: unset;
	}
	.login-wrapper .loginbox .login-right {
		float: none;
		padding: 1.875rem;
		width: 100%;
	}
	.top-action-left .float-left {
		float: none !important;
	}
	.top-action-left .btn-group {
		margin-bottom: 15px;
	}
	.top-action-right {
		text-align: center;
	}
	.top-action-right a.btn.btn-white {
		margin-bottom: 15px;
	}
	.mail-sent-time {
		float: left;
		margin-top: 10px;
		width: 100%;
	}
	.login-wrapper .loginbox .login-right h1 {
		font-size: 22px;
	}
	.error-box h1 {
		font-size: 6em;
	}
	.error-box .btn {
		font-size: 15px;
		min-width: 150px;
		padding: 8px 20px;
	}
	.dash-count {
		font-size: 16px;
		display: inline-block;
	}
	.dash-widget-header{
		display: block;
	}
	.nav {
		display: block;
	}
	.nav-tabs.menu-tabs .nav-item {
		display: block;
	}
	.nav-tabs.menu-tabs .nav-link {
		display: block;
		text-align: center;
	}
	.settings-btns .btn-orange {
		font-size: 15px;
		padding: 8px 26px;
	}
	.settings-btns .btn-grey {
		font-size: 15px;
		padding: 8px 26px;
	}
	.add-links { 
		font-size: 15px;
		padding: 8px 18px;
	}
	.settings-form .trash {
		right: 0;
		margin-bottom: 10px;
	}
	.links-cont .form-group {
		margin-bottom: 10px;
	}
	h3 {
		font-size: 20px;
	}
	.page-title {
		font-size: 20px;
	}
	.card .card-header .card-title {
		font-size: 18px;
	}
	.subscribe-head .add-button, .subscribe-head .filter-btn {
		min-width: 135px;
		padding: 6px 15px;
		height: inherit;
		border: 0;
		color: #fff;
		font-size: 12px;
		font-weight: 600;
	}
	.subscribe-employe ul li a {
		padding: 0px 20px;
		font-size: 15px;
	}
	.heading-price h2 {
		font-size: 20px;
	}
	.heading-price p {
		font-size: 14px;
	}
	.price-list {
		margin-bottom: 15px;
	}
	.btn-plan {
		padding: 6px 15px;
	}
	.subscribe-now .btn-primary {
		padding: 6px 10px;
	}
	.project-report li {
		min-width: 100px;
		padding: 5px 15px;
	}
	.select-group .form-select {
		margin-bottom: 10px;
	}
	.users-list ul {
		display: block;
	}
	.users-list ul .active {
		margin-bottom: 10px;
	}
	.users-list ul li a {
		padding: 0;
	}
	.list-pagination {
		padding: 0;
	}
	.user-active .page-title {
		font-size: 14px;
	}
	.user-active p {
		font-size: 12px;
	}
	.profile-user-box img {
		width: 35px;
		height: 35px;
		margin-right: 10px;
	}
	.profile-name-box h2 {
		font-size: 16px;
		margin-bottom: 0;
	}
	.profile-name-box p {
		font-size: 12px;
	}
	.profile-user-box {
		margin-bottom: 10px;
	}
	.profile-status .additional-info {
		margin-bottom: 10px;
	}
	.transaction-list {
		display: block;
	}
	.profile-icon-group {
		margin-bottom: 15px;
	}
	.information-list li,
	.information-list li span,
	.profile-information p,
	.additional-info h5	{
		font-size: 14px;
	}
	.additional-info {
		margin-bottom: 15px;
	}
}
@media (max-width: 575.98px) {
	.card {
		margin-bottom: 0.9375rem;
	}
	.page-wrapper > .content {
		padding: 0.9375rem 0.9375rem 0;
	}
	.chat-window {
		margin-bottom: 0.9375rem;
	}
	.card-body {
		padding: 1.25rem;
	}
	.card-header {
		padding: .75rem 1.25rem;
	}
	.card-footer {
		padding: .75rem 1.25rem;
	}
	.page-header {
		margin-bottom: 0.9375rem;
	}
	.pagination-lg .page-link {
		font-size: 1.2rem;
		padding: 0.5rem 0.625rem;
	}
	.invoice-details {
		float: left;
		text-align: left;
	}
	.fc-toolbar .fc-right {
		display: inline-block;
		float: none;
		margin: 10px auto 0;
		width: 200px;
		clear: both;
	}
	.fc-toolbar .fc-left {
		float: none;
		margin: 0 auto;
		width: 200px;
	}
	.fc-toolbar .fc-center {
		display: inline-block;
		width: 100%;
		text-align: center;
	}
	.fc-toolbar .fc-center h2 {
		width: 100%;
	}
	.chat-window .chat-cont-right .chat-header .media .media-body {
		display: none;
	}
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 160px;
	}
	.page-header .breadcrumb {
		display: none;
	}
	.notifications .noti-content {
		width: 300px;
	}
	.plan-price .nav {
		display: flex;
	}
}

.anticon.anticon-caret-up.ant-table-column-sorter-up {
	right: 7px;
	position: relative;
	bottom: 4px;
  }
  .anticon.anticon-caret-up.ant-table-column-sorter-down {
	position: relative;
  }
  .ant-table-column-sorter-up::after {
	content: "↑";
	font-size: 20px;
  }
  .ant-table-column-sorter-down::after {
	content: "↓";
	font-size: 20px;

  }
  .anticon svg {
	display: none;
  }
  .ant-table-wrapper .ant-table-column-sorter {
	position: relative;
	top: 7px;
	left: 10px;
  }

  .anticon.anticon-caret-up.ant-table-column-sorter-up {
	right: 7px;
	position: relative;
	bottom: 4px;
  }
  .anticon.anticon-caret-up.ant-table-column-sorter-down {
	position: relative;
  }
  .ant-table-column-sorter-up::after {
	content: "↑";
	font-size: 20px;
  }
  .ant-table-column-sorter-down::after {
	content: "↓";
	font-size: 20px;

  }
  .anticon svg {
	display: none;
  }
  .ant-table-wrapper .ant-table-column-sorter {
	position: relative;
	top: 7px;
	left: 10px;
  }